<div fxFlex class="item-container" fxLayout="column" (click)="navigateToPage()" data-test="documents-panel">
  <ng-container *ngIf="document">
    <div class="status-icon-container" fxFlex="10" *ngIf="document.type !== 'DriverLicense'">
      <mat-icon svgIcon="checkbox-circle-line" *ngIf="document.status && isNotReviewed"> </mat-icon>
      <mat-icon svgIcon="checkbox-circle-line-green" *ngIf="document.status && isPresent" data-test="green-tick-mark">
      </mat-icon>
      <mat-icon svgIcon="yellow-warning-icon" *ngIf="document.status && (!isRequirementsCompleted || isProcessing)">
      </mat-icon>
      <mat-icon svgIcon="error-warning-line" *ngIf="document.status && isRefused"> </mat-icon>
      <span *ngIf="!document.hasIcon && !document.status" [ngClass]="getDocUploadStatus() ? 'uploaded' : uploadStatus"
        >{{ document.uploaded }}/{{ totalDocs }}</span
      >
    </div>
    <div class="status-icon-container" fxFlex="10" *ngIf="document.type === 'DriverLicense'">
      <mat-icon svgIcon="checkbox-circle-line" *ngIf="document.status && isNotReviewed"> </mat-icon>
      <mat-icon svgIcon="checkbox-circle-line-green" *ngIf="(document.status && isPresent) && licenseUploadedDate" data-test="green-tick-mark">
      </mat-icon>
      <mat-icon svgIcon="yellow-warning-icon" *ngIf="document.status && (!isRequirementsCompleted || isProcessing)">
      </mat-icon>
      <mat-icon svgIcon="error-warning-line" *ngIf="(document.status && isRefused) || (document.status && !licenseUploadedDate)"> </mat-icon>
      <span *ngIf="!document.hasIcon && !document.status" [ngClass]="getDocUploadStatus() ? 'uploaded' : uploadStatus"
        >{{ document.uploaded }}/{{ totalDocs }}</span
      >
    </div>

    <div class="timer-icon-container" fxFlex="30">
      <mat-icon
        *ngIf="document.iconName != 'license-info-icon'"
        [svgIcon]="document.iconName"
        class="icon-color"
      ></mat-icon>
      <mat-icon *ngIf="document.iconName == 'license-info-icon'" svgIcon="license-info-icon"> </mat-icon>
    </div>

    <div class="document-title" data-test="document-name">{{ document.displayName }}</div>
    <span class="document-processing" *ngIf="isProcessing">Processing document</span>
  </ng-container>
</div>
