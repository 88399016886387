import { Inject, OnInit, AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { Status } from 'src/app/shared/status-banner/models/status.model';
import { IdentityActionSheetComponent } from 'src/app/identity-check/identity-action-sheet/identity-action-sheet.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { base64ToFile } from 'src/app/shared/utils';
import { LicenseDataService } from '../license-data.service';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { of } from 'rxjs';

const FRONT_LICENSE_DEFAULT_NAME = 'front-license';
const BACK_LICENSE_DEFAULT_NAME = 'back-lincense';
@Component({
  selector: 'app-license-outlet',
  templateUrl: './license-outlet.component.html',
  styleUrls: ['./license-outlet.component.scss']
})
export class LicenseOutletComponent implements OnInit {
  actions: Action[];
  mobile: boolean;
  status: Status;
  title: string;
  html: any;
  footer: string;
  qrImg: any;
  iOSAppQR: any;
  appStoreIcon: any;

  captures: string[] = [];
  error: any;
  isCaptured: boolean;
  WIDTH = 420;
  HEIGHT = 380;
  imageChangedEvent: any;
  profilePicture;
  canvasRotation = 0;
  @ViewChild('previewImage') previewImage: ImageCropperComponent;
  transform: ImageTransform = {};
  loading;
  frontImage: any;
  backImage: any;
  showCrop;
  frontImageUrl: any;
  backImageUrl: any;
  isFrontSave: boolean;
  finalImageFiles: any[] = [];

  isApiLoading = false;
  license: any;
  imageDetails;
  constructor(
    private readonly registrationService: RegistrationService,
    private readonly licenseDataService: LicenseDataService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly router: Router,
    private deviceService: DeviceDetectorService,
    private domSanitizer: DomSanitizer,
    @Inject('environmentData') public environment: any
  ) {
    this.actions = [];
    this.qrImg = this.environment.webQrCode;
    this.iOSAppQR = this.environment.iOSQrCode;
    this.appStoreIcon = this.environment.appStoreIcon;
  }

  ngOnInit(): void {
    const license = this.registrationService.getLicenseStore();
    this.license = license;
    if (this.deviceService.isTablet() || this.deviceService.isMobile()) {
      // checks if the device is a mobile device or tablet
      this.mobile = true;
    } else {
      this.mobile = false;
      this.html = this.domSanitizer.bypassSecurityTrustHtml(
        `<p>Please switch to a mobile device and login to your profile to proceed with the identity check process.</p><p>Thank you!</p>`
      );
    }
    if (!this.mobile) {
      this.actions.push(this.createNavigateToMobile());
      this.status = this.getSwitchToMobileMessage();
    }
  }

  onOpenActionSheet(): void {
    this.bottomSheet.open(IdentityActionSheetComponent);
  }

  onImageCropped(event: ImageCroppedEvent) {
    if (event && event.base64) {
      let name = FRONT_LICENSE_DEFAULT_NAME;
      if (this.isFrontSave) {
        name = BACK_LICENSE_DEFAULT_NAME;
      }
      const imageFile = new File([base64ToFile(event.base64)], name, { type: 'png' });
      let reader = new FileReader();
      let url;
      reader.readAsDataURL(imageFile);
      reader.onload = event => {
        url = event.target.result;
        if (!this.isFrontSave) {
          this.frontImage = imageFile;
          this.frontImageUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
        } else {
          this.backImage = imageFile;
          this.backImageUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
        }
      };
    }
  }

  onImageLoaded() {
    this.loading = false;
  }

  onCropperReady() {
    this.loading = false;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  onFinish() {
    let name = FRONT_LICENSE_DEFAULT_NAME;
    if (this.isFrontSave) {
      name = BACK_LICENSE_DEFAULT_NAME;
    }
    const imageFile = new File(
      [this.isFrontSave ? base64ToFile(this.backImageUrl) : base64ToFile(this.frontImageUrl)],
      name,
      { type: 'png' }
    );
    let reader = new FileReader();
    let url;
    reader.readAsDataURL(imageFile);
    reader.onload = event => {
      url = event.target.result;
      if (!this.isFrontSave) {
        this.frontImage = imageFile;
        this.frontImageUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
        this.finalImageFiles.push(this.frontImage);
        this.isFrontSave = true;
        this.frontImage = undefined;
      } else {
        this.backImage = imageFile;
        this.backImageUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
        this.finalImageFiles.push(this.backImage);
      }
    };
    this.isCaptured = false;
    this.showCrop = false;
    this.loading = false;
  }

  onFinishCrop() {
    if (!this.isFrontSave) {
      this.finalImageFiles.push(this.frontImage);
      this.isFrontSave = true;
      this.frontImage = undefined;
    } else {
      this.finalImageFiles.push(this.backImage);
    }
    this.isCaptured = false;
    this.showCrop = false;
    this.loading = false;
  }
  resetLicense(): void {
    this.isFrontSave = false;
    this.finalImageFiles = [];
    this.frontImage = undefined;
    this.frontImageUrl = undefined;
    this.backImage = undefined;
    this.backImageUrl = undefined;
    this.isCaptured = false;
    this.showCrop = false;
    this.loading = false;
  }

  onCancelCrop() {
    this.showCrop = false;
    this.isCaptured = false;
    this.loading = false;
    if (!this.isFrontSave) {
      this.frontImage = undefined;
      this.frontImageUrl = undefined;
    }
    if (this.isFrontSave) {
      this.backImage = undefined;
      this.backImageUrl = undefined;
    }
  }
  saveLicense(): void {
    if (this.finalImageFiles?.length > 1) {
      this.isApiLoading = true;
      const frontLicense = this.finalImageFiles.find(file => file.name === FRONT_LICENSE_DEFAULT_NAME);
      const backLicense = this.finalImageFiles.find(file => file.name === BACK_LICENSE_DEFAULT_NAME);

      const saveModel = {
        frontScanImageExtension: frontLicense.type,
        backScanImageExtension: backLicense.type
      };
      const licensePresent = this.isLicensePresent();

      if (licensePresent) {
        this.licenseDataService
          .updateLicense(saveModel, this.license.id)
          .pipe(
            first(),
            tap(
              response => {
                this.uploadLicenseImage(response, frontLicense, backLicense);
              },
              catchError(error => {
                return of(false);
              })
            )
          )
          .subscribe();
      }
      this.licenseDataService
        .uploadLicense(saveModel)
        .pipe(
          first(),
          tap(response => {
            this.uploadLicenseImage(response, frontLicense, backLicense);
          }),
          catchError(error => {
            return of(false);
          })
        )
        .subscribe();
    }
  }
  uploadLicenseImage(response, frontLicense, backLicense) {
    if (response) {
      const licenseResponse = response;

      this.licenseDataService
        .sendImageFiles(frontLicense, backLicense, response)
        .pipe(
          first(),
          tap(response => {
            if (licenseResponse?.frontScanImageUrl) {
              licenseResponse.backScanImagePresent = true;
            }
            if (licenseResponse?.backScanImageUrl) {
              licenseResponse.frontScanImagePresent = true;
            }
            this.registrationService.updateLicenseStore(licenseResponse);
            this.onNavigateToProfile();
          }),
          catchError(error => {
            return of(false);
          }),
          finalize(() => {
            this.isApiLoading = false;
          })
        )
        .subscribe();
    }
  }
  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  private isLicensePresent(): boolean {
    return this.license && this.license?.id ? true : false;
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  private createNavigateToMobile(): Action {
    return {
      name: `LOG OUT`,
      action: (data: any) => this.logout(),
      disabled: false,
      color: 'switch_to_mobile'
    } as Action;
  }

  private logout(): void {
    this.registrationService.clearDriver();
    this.registrationService.clearRegistration();
    this.router.navigate([ROUTE_SIGNIN]);
  }

  private getSwitchToMobileMessage(): Status {
    return {
      message: 'Switch to a mobile device to proceed',
      svgIcon: 'alert-circle-red',
      color: 'switch_to_mobile_banner'
    } as Status;
  }
}
