<div fxLayout="column" class="default-container" data-test="dialog-box">
  <h1 fxLayoutAlign="left" mat-dialog-title class="title-class">{{ data.title }}</h1>
  <div fxLayoutAlign="left" mat-dialog-content class="margin-bottom-lg margin-top-lg" *ngIf="data.message">
    {{ data.message }}
  </div>

  <div fxFlex></div>

  <div fxLayoutAlign="end" cdkFocusInitial fxLayout="row" mat-dialog-actions>
    <button
      *ngFor="let action of data.actions"
      mat-flat-button
      [disabled]="action.disabled"
      [color]="action.color"
      (click)="action.action()"
      data-test="action-button"
      [ngClass]="{
        'confirm-button': action.name.toLowerCase() === 'proceed',
        'delete-button': action.name.toLowerCase() === 'delete'
      }"
    >
      {{ action.name }}
    </button>
  </div>
</div>
