import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { StorageService } from 'src/app/shared/storage/storage.service';

const KEY_DRIVER = 'driver';
const equipmentTypeLabels = {
  dumpTruck: 'Dump Truck',
  flatbed: 'Flatbed',
  dryVan: 'Dry Van',
  reefer: 'Reefer',
  tanker: 'Tank',
  stTruck: 'St. Truck',
  other: 'Other'
};

@Component({
  selector: 'app-dqf-driver-employment-history',
  templateUrl: './dqf-driver-employment-history.component.html',
  styleUrls: ['./dqf-driver-employment-history.component.scss']
})
export class DqfDriverEmploymentHistoryComponent implements OnInit {
  @Input() version: number;
  data = [];
  constructor(private storageService: StorageService, private registrationService: RegistrationService) {}
  radiusOfOperations = [
    { value: 'provincial', label: 'Provincial' },
    { value: 'acrossCanada', label: 'Canada wide' },
    { value: 'canadaAndUS', label: 'Canada & US' },
    { value: 'intrastate', label: 'Intrastate' },
    { value: 'acrossUS', label: 'Across US' },
    { value: 'USAndCanada', label: 'US & Canada' }
  ];

  ngOnInit(): void {
    this.getEmploymentInfo();
  }

  private getEmploymentInfo(): void {
    const driver = this.storageService.find(KEY_DRIVER);
    if (this.version > 0) {
      this.registrationService.getEmploymentHistory(this.version).subscribe((response: any[]) => {
        this.bindData(response);
      });
    } else {
      const driver = this.storageService.find(KEY_DRIVER);
      this.bindData(driver.employment);
    }
  }

  private bindData(employment): void {
    if (employment?.length) {
      const currentEmployment = employment.find(item => item.isCurrentEmployment);
      const otherEmployments = employment.filter(item => !item.isCurrentEmployment);
      otherEmployments.sort((a, b) => {
        const dateA = new Date(a.fromDate).getTime();
        const dateB = new Date(b.fromDate).getTime();
        return dateB - dateA;
      });
      const data = currentEmployment ? [currentEmployment, ...otherEmployments] : otherEmployments;
      data.forEach(e => {
        if (e.employer) {
          const employed = {
            propertyName: 'Employer',
            propertyValue: `${e.employer}`,
            addressInfo: `${e.employerAddress}`,
            position: `${this.getPosition(e.position) ?? ''}`,
            licenseClass: `${e?.licenseType ? e?.licenseType : ''}`,
            subPropertyValue: `${dayjs(e.fromDate).format('MMM YYYY') as string} - ${dayjs(e.toDate).format(
              'MMM YYYY'
            ) as string} `,
            assignedRadius: `${this.getAssignedRadius(e.assignedRadius) ?? ''}`,
            equipmentType: `${this.getEquipmentTypeLabels(e?.equipmentTypes) ?? ''}`,
            referenceContactName: `${e?.referenceContactName ? e?.referenceContactName : ''}`,
            referenceContactNumber: `${e?.referenceContactPhoneNumber ? e?.referenceContactPhoneNumber : ''}`,
            referenceEmail: `${e?.referenceEmail ? e?.referenceEmail : ''}`,
            reasonForLeaving: `${e?.reasonForLeaving ? e?.reasonForLeaving : ''}`
          };
          this.data.push(employed);
        } else {
          const pastAddress = {
            propertyName: 'Unemployed',
            propertyValue: `${dayjs(e.fromDate).format('MMM YYYY') as string} - ${dayjs(e.toDate).format(
              'MMM YYYY'
            ) as string} `,
            reasonForLeaving: `${e?.reasonForLeaving}`
          };
          this.data.push(pastAddress);
        }
      });
    }
  }

  getPosition(position: string): string {
    switch (position) {
      case 'companyDriver':
        return 'Company Driver';
      case 'ownerOperator':
        return 'Owner Operator';
      case 'other':
        return 'Other';
      default:
        return position;
    }
  }

  getAssignedRadius(value: string): string {
    const radius = this.radiusOfOperations.find(item => item.value === value);
    return radius ? radius.label : value;
  }

  getEquipmentTypeLabels(equipmentTypes: string[]): string {
    if (equipmentTypes?.length) {
      return equipmentTypes.map(type => equipmentTypeLabels[type] || type).join(', ');
    }
  }
}
