<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5"><mat-icon svgIcon="left-arrow"></mat-icon></div>
    <div fxFlex="95" class="header-text">Documents</div>
  </div>
  <div class="content">
    <div class="document-list-container" fxFlex="100" fxLayout="column" fxLayoutGap="10px">
      <div fxFlex="30" fxLayout class="document-item-row" fxLayoutGap="15px">
        <div
          fxFlex
          fxLayout="column"
          class="document-item"
          [ngClass]="{ 'present-document-item': passportDocument?.isPresent }"
          (click)="uploadFile('Passport', passportDocument?.isPresent)"
        >
          <div fxFlex="20" fxLayout class="timer-icon-container">
            <div fxFlex *ngIf="!passportDocument?.isPresent">
              <mat-icon svgIcon="passport-fill"></mat-icon>
            </div>
            <div fxFlex="60" class="icon-align-end" *ngIf="passportDocument?.isPresent">
              <mat-icon svgIcon="passport-line"></mat-icon>
            </div>
            <div fxFlex="40" class="icon-align-end" *ngIf="passportDocument?.isPresent">
              <mat-icon svgIcon="tick-checked"></mat-icon>
            </div>
          </div>
          <div fxFlex="50">
            <div class="document-title">Passport</div>
            <div class="btn-upload-container" *ngIf="!passportDocument?.isPresent">UPLOAD</div>
          </div>
        </div>
        <div
          fxFlex
          fxLayout="column"
          class="document-item"
          [ngClass]="{ 'present-document-item': PRCardDocument?.isPresent }"
          (click)="uploadFile('PRCard', PRCardDocument?.isPresent)"
        >
          <div fxFlex="20" fxLayout class="timer-icon-container">
            <div fxFlex *ngIf="!PRCardDocument?.isPresent">
              <mat-icon svgIcon="pr-visa-fill"></mat-icon>
            </div>
            <div fxFlex="60" class="icon-align-end" *ngIf="PRCardDocument?.isPresent">
              <mat-icon svgIcon="pr-visa-line"></mat-icon>
            </div>
            <div fxFlex="40" class="icon-align-end" *ngIf="PRCardDocument?.isPresent">
              <mat-icon svgIcon="tick-checked"></mat-icon>
            </div>
          </div>
          <div fxFlex="50">
            <div class="document-title">PR Card</div>
            <div class="btn-upload-container" *ngIf="!PRCardDocument?.isPresent">UPLOAD</div>
          </div>
        </div>
      </div>
      <div fxFlex="30" fxLayout class="document-item-row margin-top-md" fxLayoutGap="15px">
        <div
          fxFlex
          fxLayout="column"
          class="document-item"
          *ngIf="isUSDriver"
          [ngClass]="{ 'present-document-item': CanadianVisa?.isPresent }"
          (click)="uploadFile('CanadaVisa', CanadianVisa?.isPresent)"
        >
          <div fxFlex="20" fxLayout class="timer-icon-container">
            <div fxFlex *ngIf="!CanadianVisa?.isPresent">
              <mat-icon svgIcon="pr-visa-fill"></mat-icon>
            </div>
            <div fxFlex="60" class="icon-align-end" *ngIf="CanadianVisa?.isPresent">
              <mat-icon svgIcon="pr-visa-line"></mat-icon>
            </div>
            <div fxFlex="40" class="icon-align-end" *ngIf="CanadianVisa?.isPresent">
              <mat-icon svgIcon="tick-checked"></mat-icon>
            </div>
          </div>
          <div fxFlex="50">
            <div class="document-title">{{ loading ? '' : 'Canada' }} Visa</div>
            <div class="btn-upload-container" *ngIf="!CanadianVisa?.isPresent">UPLOAD</div>
          </div>
        </div>
        <div
          fxFlex
          fxLayout="column"
          class="document-item"
          *ngIf="!isUSDriver"
          [ngClass]="{ 'present-document-item': USVisa?.isPresent }"
          (click)="uploadFile('USVisa', USVisa?.isPresent)"
        >
          <div fxFlex="20" fxLayout class="timer-icon-container">
            <div fxFlex *ngIf="!USVisa?.isPresent">
              <mat-icon svgIcon="pr-visa-fill"></mat-icon>
            </div>
            <div fxFlex="60" class="icon-align-end" *ngIf="USVisa?.isPresent">
              <mat-icon svgIcon="pr-visa-line"></mat-icon>
            </div>
            <div fxFlex="40" class="icon-align-end" *ngIf="USVisa?.isPresent">
              <mat-icon svgIcon="tick-checked"></mat-icon>
            </div>
          </div>
          <div fxFlex="50">
            <div class="document-title">{{ loading ? '' : 'US' }} Visa</div>
            <div class="btn-upload-container" *ngIf="!USVisa?.isPresent">UPLOAD</div>
          </div>
        </div>
        <div
        fxFlex
        fxLayout="column"
        class="document-item"
        [ngClass]="{ 'present-document-item': WorkPermit?.isPresent }"
        (click)="uploadFile('WorkPermit', WorkPermit?.isPresent)"
      >
        <div fxFlex="20" fxLayout class="timer-icon-container">
          <div fxFlex *ngIf="!WorkPermit?.isPresent">
            <mat-icon svgIcon="bill-fill" *ngIf="!WorkPermit?.isPresent"></mat-icon>
          </div>
          <div fxFlex="60" class="icon-align-end" *ngIf="WorkPermit?.isPresent">
            <mat-icon svgIcon="bill-line" *ngIf="WorkPermit?.isPresent"></mat-icon>
          </div>
          <div fxFlex="60" class="icon-align-end" *ngIf="WorkPermit?.isPresent">
            <mat-icon svgIcon="tick-checked"></mat-icon>
          </div>
        </div>
        <div fxFlex="50">
          <div class="document-title">Work Permit</div>
          <div class="btn-upload-container" *ngIf="!WorkPermit?.isPresent">UPLOAD</div>
        </div>
      </div>
      </div>
      <input
        mat-input
        #fileToUpload
        type="file"
        accept="application/pdf,.doc,.docx,application/msword"
        [disabled]="loading"
        (change)="onFileChange(fileToUpload)"
        class="hidden-input-field"
      />
    </div>
  </div>
</div>
