<div class="header-navigation-back">
  <mat-icon (click)="onNavigateToProfile()">arrow_back</mat-icon>
  <span fxFlex class="title">Abstract</span>
</div>
<app-loading-gif *ngIf="loading"></app-loading-gif>
<div fxLayout="column" fxLayoutAlign="start stretch" class="content full-height">
  <div *ngIf="uploaded && !loading" class="padding-left-md padding-right-md">
    <p>Your Driver Abstract document was uploaded to our system on {{ createdDate }}.</p>
    <p>
      If you need further assistance, please get in touch with one of our support group at
      <a href="mailto:{{ this.supportEmail }}">{{ this.supportEmail }}.</a>
    </p>
  </div>
  <div *ngIf="!uploaded && !loading" class="help-text padding-left-md padding-right-md">
    <p>
      email us at <a href="mailto:{{ this.supportEmail }}" class="help-text">{{ this.supportEmail }}.</a> if you have
      questions
    </p>
  </div>
  <div fxLayout="column" class="content padding-left-sm padding-right-sm" *ngIf="!uploaded && !loading && !attempted">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column" class="abstractForm">
      <mat-form-field>
        <input matInput placeholder="Report Source" formControlName="reportSource" required />
        <mat-error *ngIf="reportSource.touched && reportSource.invalid">
          {{ getReportSourceErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          readonly
          placeholder="Report Generation Date"
          [matDatepicker]="generationDate"
          formControlName="generationDate"
          [max]="presentDate"
          (click)="generationDate.open()"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="generationDate"></mat-datepicker-toggle>
        <mat-datepicker touchUi="true" #generationDate></mat-datepicker>
        <mat-error *ngIf="generationDate.touched && generationDate.invalid">
          {{ getGenerationDateErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          readonly
          placeholder="Valid till"
          [matDatepicker]="validToDate"
          formControlName="validToDate"
          [min]="presentDate"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="validToDate"></mat-datepicker-toggle>
        <mat-datepicker touchUi="true" #validToDate></mat-datepicker>
        <mat-error *ngIf="validToDate.touched && validToDate.invalid">
          {{ getValidToDateErrorMessage() }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
</div>
<div fxLayout="column" *ngIf="!uploaded && !loading" fxLayoutAlign="start stretch" class="botttom-position">
  <div fxLayoutAlign="center">
    <mat-error *ngIf="errorMessage" class="error padding-bottom-sm">
      {{ errorMessage }}
    </mat-error>
    <p *ngIf="successMessage">
      {{ successMessage }}
    </p>
  </div>
  <button
    *ngIf="attempted"
    mat-raised-button
    class="select-file-button"
    type="button"
    [disabled]="!attempted"
    (click)="onNavigateToProfile()"
  >
    Back
  </button>
  <button
    *ngIf="!attempted"
    mat-raised-button
    class="select-file-button"
    type="button"
    [disabled]="loading || !form.dirty || !form.valid || successMessage"
    (click)="fileToUpload.click()"
  >
    Upload
  </button>
  <input
    mat-input
    #fileToUpload
    type="file"
    accept="application/pdf, .jpeg, .jpg"
    [disabled]="loading || !form.dirty || !form.valid || successMessage"
    (change)="onFileChange(fileToUpload)"
    class="hidden-input-field"
  />
</div>
