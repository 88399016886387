<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family employment-history">
  <div class="header-navigation-back">
    <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Employment Form</span>
  </div>
  <div>
    <mat-error *ngIf="errorMessage" class="error padding-left-md">
      {{ errorMessage }}
    </mat-error>
  </div>
  <app-skeleton-emp-residence-history
    *ngIf="!isContentReady"
    isResidenceHistory="false"
  ></app-skeleton-emp-residence-history>
  <div [hidden]="!isContentReady">
    <div class="content" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="column" class="component-container">
        <div [formGroup]="form" fxLayout="column" class="addExperienceForm">
          <div class="form-title">{{ editFormTitle }}</div>
          <div fxFlex fxFlexAlign="end">
            <button
              mat-raised-button
              class="btn-save margin-right-sm"
              (click)="onSubmit(form)"
              [disabled]="!form.valid"
              data-test="save-button"
            >
              Save
            </button>
            <button mat-button class="btn-cancel" (click)="onCancel()">Cancel</button>
            <button mat-raised-button *ngIf="enableLogButton" (click)="onLog()" color="primary">Log Form</button>
          </div>
          <div class="address-fields" fxLayout="column">
            <mat-form-field>
              <input
                matInput
                placeholder="Employer Name"
                formControlName="employer"
                required
                data-test="employer-name-form-field"
              />
              <mat-error *ngIf="employer.touched && employer.invalid">
                {{ getEmployerNameErrorMessage() }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <input
                matInput
                #empAddressText
                formControlName="employerAddress"
                placeholder="Employer Address"
                data-test="employer-address-field"
                required
              />
              <mat-error *ngIf="employerAddress.touched && employerAddress.invalid">
                {{ getEmployerAddressErrorMessage() }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="From (month, year)"
                [matDatepicker]="fromDatePicker"
                formControlName="fromDate"
                [max]="toDate.value || maxToDate"
                [min]="minFromDate"
                required
                (click)="onDatePickerClick(fromDatePicker)"
                onkeydown="return false;"
              />
              <mat-datepicker-toggle matSuffix [for]="fromDatePicker" data-test="date-picker"></mat-datepicker-toggle>
              <mat-datepicker
                #fromDatePicker
                startView="multi-year"
                touchUi="true"
                (monthSelected)="chosenMonthHandler($event, fromDatePicker, form)"
              >
              </mat-datepicker>
              <mat-error *ngIf="fromDate.touched && fromDate.invalid">
                {{ getFromDateErrorMessage() }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input
                id="empTo"
                matInput
                [disabled]="isFirstRecord || isCurrentEmployment?.value"
                placeholder="To (month, year)"
                [matDatepicker]="toDatePicker"
                [max]="maxToDate"
                [min]="fromDate.value || minFromDate"
                formControlName="toDate"
                required
                (click)="onDatePickerClick(toDatePicker)"
                onkeydown="return false;"
              />
              <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
              <mat-datepicker
                #toDatePicker
                startView="multi-year"
                touchUi="true"
                (monthSelected)="chosenMonthHandler($event, toDatePicker, form, 'toDateControl')"
              >
              </mat-datepicker>
              <mat-error *ngIf="toDate.touched && toDate.invalid">
                {{ getToDateErrorMessage() }}
              </mat-error>
            </mat-form-field>

            <mat-form-field data-test="position-form-field">
              <mat-label>Position Held</mat-label>
              <mat-select formControlName="position" placeholder="Position Held" required>
                <mat-option value="-">--</mat-option>
                <mat-option
                  class="dropdown-values"
                  *ngFor="let position of positions"
                  [value]="position.value"
                  data-test="position-form-field-option"
                  >{{ position.displayName }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('position').value === 'other'">
              <input
                matInput
                placeholder="Other Position Details"
                formControlName="otherPosition"
                data-test="other-position-form-field"
                required
              />
              <mat-error *ngIf="otherPosition.touched && otherPosition.invalid">
                {{ getPositionErrorMessage() }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="form.get('position').value === 'companyDriver' || form.get('position').value === 'ownerOperator'"
            >
              <mat-label>Assigned Radius </mat-label>
              <mat-select formControlName="assignedRadius" required data-test="assigned-radius">
                <mat-option
                  *ngFor="let radius of assignedRadiusValues"
                  [value]="radius.value"
                  data-test="assigned-radius-value"
                >
                  {{ radius.label }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="assignedRadius.touched && assignedRadius.invalid">
                {{ getRequiredFieldMessage('assignedRadius') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="form.get('position').value === 'companyDriver' || form.get('position').value === 'ownerOperator'"
            >
              <mat-label>License Class </mat-label>
              <mat-select formControlName="licenseType" required data-test="license-class">
                <mat-option *ngFor="let license of provinceLicenseClass" [value]="license" data-test="class-value">
                  {{ license }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="licenseType.touched && licenseType.invalid">
                {{ getRequiredFieldMessage('licenseType') }}
              </mat-error>
            </mat-form-field>

            <mat-label *ngIf="form.get('position').value === 'companyDriver' || form.get('position').value === 'ownerOperator'"
             class="equipment-type-label">Equipment Type(s) </mat-label>

            <div
              fxLayout="row wrap"
              class="truck-type"
              *ngIf="form.get('position').value === 'companyDriver' || form.get('position').value === 'ownerOperator'"
            >
              <div fxFlex="30" class="margin-left-xs margin-right-xs margin-top-sm outline">
                <mat-checkbox formControlName="dumpTruck" name="dumpTruck" labelPosition="after" data-test="dump-truck"
                  >Dump Truck
                </mat-checkbox>
              </div>

              <div fxFlex="30" class="margin-left-xs margin-right-xs margin-top-sm outline">
                <mat-checkbox formControlName="straightTruck" name="straightTruck" labelPosition="after" data-test="st-truck">
                  St. Truck
                </mat-checkbox>
              </div>

              <div fxFlex="30" class="margin-left-xs margin-right-xs margin-top-sm outline">
                <mat-checkbox formControlName="flatbed" name="flatbed" labelPosition="after"
                  >Flatbed</mat-checkbox
                >
              </div>

              <div fxFlex="30" class="margin-left-xs margin-right-xs margin-top-sm outline">
                <mat-checkbox formControlName="dryVan" name="dryVan" labelPosition="after" data-test="dry-van"
                  >Dry van
                </mat-checkbox>
              </div>

              <div fxFlex="30" class="margin-left-xs margin-right-xs margin-top-sm outline">
                <mat-checkbox formControlName="reefer" name="reefer" labelPosition="after" data-test="reefer"
                  >Reefer
                </mat-checkbox>
              </div>

              <div fxFlex="30" class="margin-left-xs margin-right-xs margin-top-sm outline">
                <mat-checkbox formControlName="tanker" class="full-width" name="tanker" labelPosition="after" 
                >Tank</mat-checkbox>
              </div>

              <div fxFlex="30" class="margin-left-xs margin-right-xs margin-top-sm outline">
                <mat-checkbox formControlName="other" class="full-width" name="other" labelPosition="after" data-test="other"
                > Other</mat-checkbox>
              </div>
              <mat-error *ngIf="form.hasError('atLeastOneCheckboxRequired') && form.touched">
                {{ getRequiredFieldMessage('equipmentType') }}
              </mat-error>
            </div>

            <mat-form-field>
              <input
                matInput
                placeholder="Reference Contact Name"
                formControlName="referenceContactName"
                required
                data-test="reference-contact-name-form-field"
              />
              <mat-error *ngIf="referenceContactName.touched && referenceContactName.invalid">
                {{ getReferenceContactNameErrorMessage() }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-hint align="start">###-###-####</mat-hint>
              <input
                matInput
                appPhoneMask
                maxlength="12"
                placeholder="Reference Contact Number"
                formControlName="referenceContactPhoneNumber"
                required
                data-test="reference-contact-number-form-field"
              />
              <mat-error *ngIf="referenceContactPhoneNumber.touched && referenceContactPhoneNumber.invalid">
                {{ getreferenceContactPhoneNumberErrorMessage() }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                placeholder="Reference Email"
                formControlName="referenceEmail"
                required
                data-test="reference-email-form-field"
              />
              <mat-error *ngIf="referenceEmail.touched && referenceEmail.invalid">
                {{ getReferenceEmailErrorMessage() }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              *ngIf="
                ((!isFirstRecord && !employmentObj) || (employmentObj && !employmentObj?.isCurrentEmployment)) &&
                !isModifyNewCurrentAddress
              "
            >
              <input
                matInput
                placeholder="Reason For Leaving"
                formControlName="reasonForLeaving"
                data-test="reason-for-leaving-form-field"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
