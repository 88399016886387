<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground birth-location">
  <mat-error *ngIf="errorMessage" class="error padding-left-md">
    {{ errorMessage }}
  </mat-error>
  <form [formGroup]="criminalConvictionForm">
    <div class="header-navigation-back">
      <mat-icon (click)="navigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
      <span fxFlex class="title">Conviction</span>
    </div>
    <div class="conviction-container" data-test="conviction-container">
      <div class="info">
        <p>All declared convictions will be included in your report</p>
      </div>
      <div *ngIf="!showConvictionToAdd && !showAddedConvictions">
        <div class="has-conviction">
          <mat-label class="conviction-label">Do you have any convictions to add?</mat-label>
          <mat-form-field class="full-width">
            <mat-select (selectionChange)="onChange($event.value)" required data-test="has-conviction">
              <mat-option value="yes" data-test="yes-conviction">Yes</mat-option>
              <mat-option value="no" data-test="no-conviction">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div [ngClass]="!noConvictions ? 'declare-info' : 'no-conviction-info'" data-test="conviction-info">
          <h3>
            Do not declare the following
          </h3>
          <ul>
            <li>
              A conviction for which you have a Record Suspension (formerly pardon) in accordance with the Criminal
              Records Act.
            </li>
            <li>A conviction where you were a ‘young person’ under the Youth Criminal Justice Act.</li>
            <li>An absolute or Conditional Discharge, pursuant to section 730 of the Criminal Code.</li>
            <li>An offense for which were not convicted.</li>
            <li>Any provincial or municipal offense.</li>
            <li>Any charges dealt with outside of Canada.</li>
            <li>
              Note that a Certified Criminal Record can only be issued based on the submission of fingerprints to the
              RCMP National Repository on Criminal Records.
            </li>
          </ul>
        </div>
        <div class="btn-container" *ngIf="noConvictions">
          <button class="btn-finish full-width" (click)="showCompleteJobConfirmation()" data-test="finish-button">
            FINISH
          </button>
        </div>
      </div>
      <div *ngIf="showConvictionToAdd && !showAddedConvictions" class="add-conviction" data-test="add-conviction">
        <mat-label class="conviction-label">conviction offense*</mat-label>
        <mat-form-field class="full-width">
          <mat-select formControlName="convictionOffense" data-test="nature-of-offense">
            <mat-option *ngFor="let offense of criminalOffense" [value]="offense" data-test="offense-type"
              >{{ offense }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              criminalConvictionForm.get('convictionOffense').touched &&
              criminalConvictionForm.get('convictionOffense').invalid
            "
          >
            {{ requiredMessage }}</mat-error
          >
        </mat-form-field>

        <mat-label class="conviction-label">Date of Sentence*</mat-label>
        <mat-form-field class="full-width">
          <input
            matInput
            readonly
            [matDatepicker]="dateOfSentence"
            formControlName="dateOfSentence"
            [max]="maxDate"
            (click)="dateOfSentence.open()"
            data-test="data-of-sentence"
          />
          <mat-datepicker-toggle matSuffix [for]="dateOfSentence" data-test="from-date-form-field">
            <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dateOfSentence touchUi="true"> </mat-datepicker>
          <mat-error
            *ngIf="
              criminalConvictionForm.get('dateOfSentence').touched &&
              criminalConvictionForm.get('dateOfSentence').invalid
            "
          >
            {{ requiredMessage }}
          </mat-error>
        </mat-form-field>

        <mat-label class="conviction-label">Court Location*</mat-label>
        <mat-form-field class="full-width">
          <input
            matInput
            formControlName="courtLocation"
            #courtLocation
            (focus)="getLocation()"
            data-test="court-location-field"
          />
          <mat-error
            *ngIf="
              criminalConvictionForm.get('courtLocation').touched && criminalConvictionForm.get('courtLocation').invalid
            "
          >
            {{ requiredMessage }}</mat-error
          >
        </mat-form-field>

        <mat-label class="conviction-label">Description*</mat-label>
        <mat-form-field class="full-width">
          <input matInput formControlName="description" data-test="description-field" />
          <mat-error
            *ngIf="
              criminalConvictionForm.get('description').touched && criminalConvictionForm.get('description').invalid
            "
          >
            {{ requiredMessage }}</mat-error
          >
        </mat-form-field>
        <div class="btn-container btn-flex-view">
          <button class="btn-save-conviction full-width" (click)="addConviction(false)" data-test="save-conviction">
            SAVE CONVICTION
          </button>
          <button class="btn-cancel  full-width" (click)="cancelConviction()" data-test="cancel-conviction">
            CANCEL
          </button>
        </div>
      </div>
      <div class="added-convictions" *ngIf="showAddedConvictions" data-test="added-conviction">
        <div
          class="conviction-record"
          *ngFor="let data of convictionsData; let idx = index"
          data-test="conviction-record"
        >
          <div class="conviction-info">
            <p class="conviction-date">{{ data.sentenceDate | date: 'dd MMM yyyy' }}</p>
            <span class="update-icon">
              <mat-icon
                svgIcon="edit-black"
                class="icons-list"
                (click)="onUpdateConviction(data, idx)"
                data-test="edit-conviction-record"
              ></mat-icon>
              <mat-icon
                class="icons-list"
                svgIcon="delete-sweep-outlined"
                (click)="onDeleteConviction(idx)"
                data-test="delete-conviction-record"
              ></mat-icon>
            </span>
            <p class="conviction-nature" data-test="conviction-offense">
              {{ data.offense }}
            </p>
          </div>
        </div>
        <div class="btn-container">
          <button
            class="btn-save full-width"
            (click)="addConviction(true)"
            *ngIf="convictionsData?.length < 3"
            [disabled]="loading"
            data-test="add-conviction"
          >
            ADD CONVICTION
          </button>
          <button
            class="btn-finish full-width"
            *ngIf="convictionsData?.length"
            (click)="showCompleteJobConfirmation()"
            [disabled]="loading"
            data-test="save-all-conviction"
          >
            FINISH
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
