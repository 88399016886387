/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, AfterViewInit, NgZone, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { RegistrationService } from '../shared/registration.service';
import { FormGroup, Validators, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { Driver } from 'src/app/shared/models/driver.model';
import { environment } from 'src/environments/environment';
import { first, catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { SubSink } from 'subsink';
import { tap, onErrorResumeNext } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { RegistrationState, DriverStateModel } from 'src/app/registration/state/registration.state';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { of } from 'rxjs';
import { VEHICLE_TYPE } from '../constants';
import { DobAgeValidator } from 'src/app/shared/custom-form-validators/dob-age.validator';
class AddressModel {
  fullAddress: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
}

const REQUIRED_FIELD_MESSAGE = 'Required';
const validAddressMessage = 'Please enter a full valid address';
const MaximumLengthFieldMessage = (value: number) => `Must be less than ${value} characters`;
const DEFAULT_PROFILE_IMAGE = '/assets/images/default-profile-picture.png';
const DECRYPTED_SIN_VALUE = '***-***-***';
const DOB_AGE_ERROR_MESSAGE = 'You must be at least 18 years old to proceed.';
const CANADA_WORK_STATUES = [
  {
    name: 'canWorkPermit',
    displayName: 'Work Permit'
  },
  {
    name: 'canResident',
    displayName: 'Resident'
  },
  {
    name: 'canCitizen',
    displayName: 'Citizen'
  }
];
const USA_WORK_STATUES = [
  {
    name: 'usWorkVisa',
    displayName: 'Work Visa'
  },
  {
    name: 'usResident',
    displayName: 'Resident'
  },
  {
    name: 'usCitizen',
    displayName: 'Citizen'
  }
];
const COUNTRY_WORK_STATUS = {
  Canada: CANADA_WORK_STATUES,
  USA: USA_WORK_STATUES
};
@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})
export class PersonalInformationComponent implements OnInit, AfterViewInit, OnDestroy {
  form: FormGroup;
  loading: boolean;
  isSinLoading: boolean = false;
  errorMessage: string;
  maxDate: Date = new Date();
  phone: string;
  email: string;
  driverID: string;
  isPersonalInfoCompleted: boolean;
  prefillPersonalData: boolean;
  showNavBar: boolean = true;
  driverState: DriverStateModel;
  private driver: any;
  addressModel: AddressModel;
  isEncrypted: boolean;
  decryptedSin: string;
  encryptedSin: string;
  workStatues = [];
  private subs = new SubSink();
  private profilePicturePath: ProfilePicture = new ProfilePicture();
  profilePicture: any;
  @ViewChild('addressText', { static: false }) addressText: any;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly location: Location,
    private readonly zone: NgZone,
    private readonly fb: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly appService: AppService,
    private readonly store: Store,
    private readonly dateTimeService: DateTimeService,
    private readonly dobAgeValidator: DobAgeValidator,
    @Inject('environmentData') public environment: any
  ) {
    this.addressModel = new AddressModel();
    this.loading = false;
    this.errorMessage = undefined;
    this.activatedRoute.url.subscribe(params => {
      if (params[0]) {
        this.prefillPersonalData = false;
      } else {
        this.prefillPersonalData = true;
      }
    });
  }

  ngOnInit() {
    this.form = this.createForm();
    this.driver = this.registrationService.getDriver();
    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(
          onErrorResumeNext(
            this.registrationService.loadProfilePicture().pipe(
              catchError(error => {
                return of(false);
              })
            )
          ),
          tap(() => {
            this.profilePicturePath = this.store.selectSnapshot(RegistrationState.profilePicture);
          }),
          tap(() => (this.driver = this.store.selectSnapshot(RegistrationState.driver))),
          catchError(error => {
            return of(false);
          })
        )
        .subscribe(() => {
          if (this.profilePicturePath && this.profilePicturePath.fileUrl) {
            this.profilePicture = this.profilePicturePath.fileUrl;
          } else {
            this.profilePicture = DEFAULT_PROFILE_IMAGE;
          }
        })
    );
    if (this.driver) {
      this.driverID = this.driver.driverID;
      this.email = this.driver.email;
      this.phone = this.driver.phoneNumber;
      this.populateForm(this.driver);
    }
    if (this.appService.isNativeApp) {
      this.showNavBar = false;
    }
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete(this.addressText);
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }
  goBack(): void {
    this.location.back();
  }
  onNavigateToProfilePicture(): void {
    this.registrationService.navigateToProfilePicture(this.activatedRoute);
  }

  onSubmit(): void {
    if (!this.form.valid) {
      this.validateAllFormFields(this.form);
      return;
    }
    this.loading = true;
    this.errorMessage = undefined;
    const driverModel = this.prepareSaveModel(this.driver, this.form);
    this.subs.add(
      this.registrationService
        .updateDriver(driverModel)
        .pipe(first())
        .subscribe(
          (driver: Driver) => {
            this.driver = driver;
            this.errorMessage = undefined;
            this.loading = false;
          },
          (error: string) => {
            this.errorMessage = environment.errorMessage;
            this.loading = false;
          }
        )
    );
  }
  get dateOfBirth() {
    return this.form.get('dateOfBirth');
  }
  get gender() {
    return this.form.get('gender');
  }
  get address() {
    return this.form.get('address');
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      dateOfBirth: [
        '',
        Validators.compose([(ctrl: FormControl) => this.dobAgeValidator.validate(ctrl), Validators.required])
      ],
      gender: ['', Validators.required],
      address: [
        '',
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.pattern(
            '^[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*,[a-zA-Z0-9- àâçéèêëîïôûùüÿñ]*, [A-Z]{2} [A-Z0-9 ]*, [A-Za-z]+$'
          )
        ]
      ],
      // ssn: ['', [Validators.required, Validators.minLength(12)]],
      phone: ['', [Validators.required, Validators.minLength(12)]],
      workStatus: ['', [Validators.required]],
      id: ['']
    });
    form.addControl('sin', this.fb.control('', [Validators.minLength(11)]));
    form.addControl('emergencyContacts', this.fb.array([this.createRequiredEmergencyContactFormGroup()]));

    return form;
  }

  createRequiredEmergencyContactFormGroup(): FormGroup {
    return this.fb.group({
      emergencyContactName: ['', Validators.required],
      emergencyContactRelation: ['', Validators.required],
      emergencyContactPhone: ['', [Validators.required, Validators.maxLength(12)]]
    });
  }

  createEmergencyContactFormGroup(): FormGroup {
    return this.fb.group({
      emergencyContactName: [''],
      emergencyContactRelation: [''],
      emergencyContactPhone: ['', Validators.maxLength(12)]
    });
  }

  addEmergencyContact() {
    const emergencyContacts = this.form.get('emergencyContacts') as FormArray;
    emergencyContacts.push(this.createEmergencyContactFormGroup());
  }

  get emergencyContacts(): FormArray {
    return this.form.get('emergencyContacts') as FormArray;
  }

  getDateOfBirthErrorMessage(): string {
    const errors = this.dateOfBirth?.errors;
    return errors?.invalidAge ? DOB_AGE_ERROR_MESSAGE : errors?.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getGenderErrorMessage(): string {
    return this.gender.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getAddressErrorMessage(): string {
    return this.address.errors.required
      ? REQUIRED_FIELD_MESSAGE
      : this.address.errors.pattern
      ? validAddressMessage
      : this.address.errors.maxlength
      ? MaximumLengthFieldMessage(this.address.errors.maxlength.requiredLength)
      : '';
  }
  decryptSin(): void {
    this.isEncrypted = !this.isEncrypted;
    if (this.isEncrypted) {
      this.form.get('sin').setValue(DECRYPTED_SIN_VALUE);
    } else {
      this.isSinLoading = true;
      if (this.encryptedSin) {
        this.isSinLoading = false;

        this.form.get('sin').setValue(this.formatPhoneNumberForDisplay(this.encryptedSin));
      } else {
        this.subs.add(
          this.registrationService
            .loadDriverWithDecryptedSin('sin')
            .pipe(
              catchError(error => {
                this.isSinLoading = false;
                const errorMessage = error.message;
                console.error(errorMessage);
                return of(false);
              })
            )
            .subscribe(res => {
              if (res) {
                if (res?.sin) {
                  this.isSinLoading = false;
                  this.encryptedSin = res.sin;
                  this.form.get('sin').setValue(this.formatPhoneNumberForDisplay(this.encryptedSin));
                }
              }
            })
        );
      }
    }
    this.form.get('sin').setErrors(null);
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  onDatePickerClick(datepicker: any) {
    datepicker.open();
  }

  private prepareSaveModel(driver: Driver, form: FormGroup): Driver {
    const formModel = form.value;

    this.addressModel = this.parseAddress(formModel.address);
    const numbersOnlyPhoneNumber = formModel.phone.replace(/-/g, '');

    const filteredEmergencyContacts = formModel.emergencyContacts
      .map((contact: any) => ({
        ...contact,
        emergencyContactPhone: contact.emergencyContactPhone.replace(/-/g, '')
      }))
      .filter((contact: any) => {
        return (
          contact.emergencyContactName?.trim() ||
          contact.emergencyContactPhone?.trim() ||
          contact.emergencyContactRelation?.trim()
        );
      });

    const formData = {
      birthday: this.dateTimeService.formatDateTime(formModel.dateOfBirth, 'YYYY-MM-DD'),
      gender: formModel.gender,
      address: formModel.address,
      city: this.addressModel.city,
      state: this.addressModel.state,
      zip: this.addressModel.zipcode,
      country: this.addressModel.country,
      phoneNumber: numbersOnlyPhoneNumber,
      firstName: formModel.firstName,
      lastName: formModel.lastName,
      emergencyContacts: filteredEmergencyContacts,
      workStatus: formModel.workStatus
    } as Driver;

    if (formModel.sin) {
      if (!formModel.sin.includes('*') && !this.form.get('sin').pristine) {
        formData.sin = formModel.sin.replaceAll('-', '');
      }
    }
    if (driver?.assignedVehicleType) {
      driver.assignedVehicleType = VEHICLE_TYPE.find(type => type.displayName === driver.assignedVehicleType)?.name;
    }

    const driverToSave = Object.assign({}, driver, formData);
    if (driverToSave?.sin) {
      if (driverToSave.sin.includes('*')) {
        delete driverToSave.sin;
      }
    }
    return driverToSave;
  }
  private populateForm(model: Driver): void {
    let localBirthdayTime = new Date(model.birthday);
    localBirthdayTime.setMinutes(localBirthdayTime.getMinutes() + localBirthdayTime.getTimezoneOffset());
    if (model.sin) {
      if (model.sin.includes('*')) {
        this.isEncrypted = true;
        this.form.get('sin').setValue(DECRYPTED_SIN_VALUE);
      } else {
        this.form.get('sin').setValue('');
      }
    }
    this.form.patchValue({
      firstName: model ? (model.firstName ? model.firstName : '') : '',
      lastName: model ? (model.lastName ? model.lastName : '') : '',
      gender: model ? (model.gender ? model.gender : '') : '',
      dateOfBirth: model ? (localBirthdayTime ? localBirthdayTime : '') : '',
      address: model ? (model.address ? model.address : '') : '',
      phone: model ? (model.phoneNumber ? this.formatPhoneNumberForDisplay(model.phoneNumber) : '') : '',
      id: model ? model.id : '',
      workStatus: model ? (model.workStatus ? model.workStatus : '') : ''
    });
    const emergencyContactsFormArray = this.form.get('emergencyContacts') as FormArray;
    emergencyContactsFormArray.clear();

    if (model.emergencyContacts && Array.isArray(model.emergencyContacts)) {
      model.emergencyContacts.forEach(contact => {
        emergencyContactsFormArray.push(
          this.fb.group({
            emergencyContactName: [contact.emergencyContactName || ''],
            emergencyContactRelation: [contact.emergencyContactRelation || ''],
            emergencyContactPhone: [this.formatPhoneNumberForDisplay(contact.emergencyContactPhone) || '']
          })
        );
      });
    }

    if (emergencyContactsFormArray.length === 0) {
      emergencyContactsFormArray.push(this.createRequiredEmergencyContactFormGroup());
    }
    this.workStatues = COUNTRY_WORK_STATUS[model.country] || CANADA_WORK_STATUES;
  }

  private getPlaceAutocomplete(addresstext: any): void {
    const autocomplete = new google.maps.places.Autocomplete(addresstext.nativeElement, {
      componentRestrictions: {
        country: ['US', 'CA']
      },
      types: []
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      if (place) {
        this.zone.run(() => {
          this.form.patchValue({ address: place.formatted_address });

          const addressModel = this.parseAddress(place.formatted_address);
          this.workStatues = COUNTRY_WORK_STATUS[addressModel.country] || CANADA_WORK_STATUES;

          if (addressModel.country !== this.driver.country) {
            this.form.patchValue({ workStatus: '' });
          }
        });
      }
    });
  }

  parseAddress(address: string): AddressModel {
    const parsedAddressArr = address.split(',');
    const addressModel = new AddressModel();
    if (address && parsedAddressArr.length === 4) {
      addressModel.fullAddress = address;
      addressModel.city = parsedAddressArr[1].trim();
      addressModel.state = parsedAddressArr[2].trim();
      addressModel.zipcode = addressModel.state.substring(3, addressModel.state.length).trim();
      addressModel.state = addressModel.state.split(' ')[0];
      addressModel.country = parsedAddressArr[3].trim();
    }

    return addressModel;
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  private formatPhoneNumberForDisplay(phoneNumber: string): string {
    if (phoneNumber.length > 6)
      return `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, 10)}`;

    return phoneNumber;
  }
}
