import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { SubSink } from 'subsink';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { DriverStateModel } from 'src/app/registration/state/registration.state';
import { ActivatedRoute, Router } from '@angular/router';

import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CompleteProfileSheetComponent } from '../../driver-qualification-files/complete-profile-sheet/complete-profile-sheet.component';
import { DriverQualificationService } from '../../driver-qualification-files/service/driver-qualification.service';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner/progress-spinner';
import { Driver } from 'src/app/shared/models/driver.model';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { FrameNavigationService } from 'src/app/frame/shared/frame-navigation.service';
import { ROUTE_DQF, ROUTE_DQF_DOCUMENT_UPLOAD, ROUTE_DQF_REVIEW } from 'src/app/shared/routes';
import { DRIVER_TYPE_MAP } from 'src/app/driver-qualification-files/constants';
import { of } from 'rxjs';
import { License } from 'src/app/shared/models/license.model';
import { ShipmentsDataService } from 'src/app/shipments/shared/shipments-data.service';
import { SHIPMENTS_ROUTE, SHIPMENTS_ROUTE_MY_STATUS } from 'src/app/shipments/shared/routes';

const DQF_STATUS_CREATED = 'Created';
const DQF_STATUS_REJECTED = 'Rejected';
const DQF_STATUS_UNDER_REVIEW = 'PendingCarrierApproval';
const DQF_STATUS_DRIVER_SIGNATURE_SUBMITED = 'DriverSignatureSubmitted';
const DQF_STATUS_PROCESSING = 'Processing';
const DRIVER_QUALIFICATION_FILE = 'DriverQualificationsFile';
const DQF_STATUS_PENDINGAPPROVAL = 'PendingCarrierApproval';
const DQF_STATUS_APPROVED = 'Approved';
const DQF_STATUS_CARRIERPROCESSINGCOMPLETED = 'CarrierProcessingCompleted';
const DQF_STATUS_APPROVEDPROCESSING = 'ApprovedProcessing';
const DQF_STATUS_WITHDRAWN = 'Withdrawn';

const STATUS_CODE = 200;
const PROFILE_STATUS_INCOMPLETE = 'Incomplete';

const ACTION_GET_PROFILE_IMAGE = 'get profile image';
const ACTION_GET_DQF = 'get DQG';
const DISABLE_TOP_LOADING_BAR = true;
const LICENSE_DOCUMENT_TYPE = 'DriverLicense';
const PRCARD_DOCUMENT_TYPE = 'PRCard';
const TRAINING_CERTI_DOCUMENT = 'TrainingCertificate';
const PASSPORT_DOCUMENT_TYPE = 'Passport';
const USVISA_DOCUMENT_TYPE = 'USVisa';
const CANADA_VISA_DOCUMENT_TYPE = 'CanadaVisa';
const ADDITIONAL_DOCUMENTS = [
  PRCARD_DOCUMENT_TYPE,
  // TRAINING_CERTI_DOCUMENT,
  PASSPORT_DOCUMENT_TYPE
];
const SHIPMENT_STATUS_CONFIRMATION_PENDING = 'confirmation pending';

const UPLOADED_STATUS = 'uploaded';
export interface DialogData {
  firstName: string;
  lastName: string;
}
export interface ProfileAction {
  displayName: string;
  clickHandler: () => void;
  order: number;
  isComplete: boolean;
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  isProfileContentReady: boolean = false;
  isRequireInfoReady: boolean = false;
  profilePicture: ProfilePicture = new ProfilePicture();
  loading: boolean;
  errorMessage: string;
  driver: DriverStateModel;
  notifications: Notification[];
  identityCheck: Notification;
  identityCheckReview: Notification;
  insurance: Notification;
  driverAbstract: Notification;
  medicalExamination: Notification;
  driveTest: Notification;
  resume: Notification;
  profileSectionCompletionCount: number;
  totalRequiredProfileSectionCount: number = 6;
  singleOpen: number;
  driverTypeInstruction: string;
  profileForReview: boolean;
  driverStatus: string;
  isCarrierDriver: boolean;
  totalAdditionalInfoCount = 5;
  driverName: string;
  driverEmail: string;
  driverId: string;
  profilePicturePath: any;
  requiredInformation: ProfileAction[] = [];
  additionalInformation: ProfileAction[] = [];
  mode: ProgressSpinnerMode = 'determinate';
  showPercentage = 0;
  requiredCompleted = 0;
  additionalRequired = 0;
  dqfs: any[] = [];
  driverType: string;
  allDqfs;
  licenseDetails: License;
  latestShipment;
  isDriverProfileIncomplete: boolean

  private subs = new SubSink();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly bottomSheet: MatBottomSheet,
    private readonly registrationService: RegistrationService,
    private readonly driverQualificationService: DriverQualificationService,
    private readonly dateTimeService: DateTimeService,
    private readonly router: Router,
    private readonly frameNavigationService: FrameNavigationService,
    private readonly shipmentDataService: ShipmentsDataService,
    @Inject('environmentData') public environment: any
  ) {
    this.loading = false;
    this.errorMessage = undefined;
  }

  ngOnInit(): void {
    this.isCarrierDriver = this.registrationService.getDriver()?.companyAssociatedId ? true : false;
    this.isDriverProfileIncomplete = this.checkIfDriverProfileIncomplete()
    this.subs.sink = this.registrationService.loadDriver(DISABLE_TOP_LOADING_BAR).subscribe((response: Driver) => {
      if (response) {
        this.loadProfileImage();
        this.getLicense(response);
      }
    });
    if (this.environment?.featureFlags?.isDQFEnable) {
      const driver = this.registrationService.getDriver();
      this.driverQualificationService
        .getDqfs()
        .pipe(
          catchError((error: any) => {
            return of(false);
          }),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe(res => {
          if (res && res.length > 0) {
            this.allDqfs = this.registrationService.getDqfsWithDriverType(res);
            this.dqfs = this.allDqfs
              .sort((a, b) => new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime())
              .slice(0, 2);
          }
        });
    }
    this.getNewShipments();
  }
  getNewShipments(): void {
    const dispatcherContact = this.registrationService.getDispatcherContact();
    if (dispatcherContact) {
      const driverId = this.registrationService.getDriver()?.id;

      this.shipmentDataService
        .fetchMyShipments(driverId)
        .pipe(
          first(),
          tap(response => {
            const confirmationShipments = response?.filter(
              shipment => shipment.status === SHIPMENT_STATUS_CONFIRMATION_PENDING
            );
            const latestShipment = confirmationShipments?.sort(
              (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
            );
            if (latestShipment?.length) {
              this.latestShipment = latestShipment[0];
            }
          })
        )
        .subscribe();
    }
  }
  onNavigateToPersonalInformation(): void {
    this.registrationService.navigateToPersonalInformation(this.activatedRoute);
  }
  navigateToShipmentDetail(shipment: any): void {
    let path = `/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_MY_STATUS}/${shipment.id}`;
    this.router.navigate([path]);
  }
  closeShipmentNotification(): void {
    this.latestShipment = undefined;
  }
  private bindData(response: Driver): void {
    this.driverType = DRIVER_TYPE_MAP[response.companyDriverType];
    this.driverName = `${response.firstName} ${response.lastName}`;
    this.driverEmail = response.email;
    this.driverId = response.driverID;
    this.requiredInformation = this.loadRequiredInformation(response);
    this.additionalInformation = this.loadAdditionalInformation(response);
    const completeValues = this.requiredInformation.filter(element => element.isComplete);
    this.showPercentage = +((+completeValues?.length / this.totalRequiredProfileSectionCount) * 100).toFixed(0);
    this.requiredCompleted = completeValues?.length;
    const completeAdditionalValues = this.additionalInformation.filter(element => element.isComplete);
    this.additionalRequired = completeAdditionalValues?.length;
    this.driverStatus = response.identityStatus;
    this.isRequireInfoReady = true;
  }
  private loadProfileImage(): void {
    this.registrationService
      .loadProfileImageThumbnail(DISABLE_TOP_LOADING_BAR)
      .pipe(
        catchError((error: any) => {
          this.isProfileContentReady = true;
          return of(false);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.profilePicture = res;
          this.profilePicturePath = this.profilePicture.fileUrl;
          this.isProfileContentReady = true;
        }
      });
  }
  private getLicense(driverResponse): void {
    const license: any = this.registrationService.getLicenseStore();
    if (license) {
      this.licenseDetails = license;
    }
    this.bindData(driverResponse);
  }
  private loadRequiredInformation(driverInfo: Driver): ProfileAction[] {
    return [
      {
        displayName: 'License',
        clickHandler: () => {
          this.registrationService.onNavigateToLicense(this.activatedRoute);
        },
        isComplete: driverInfo.licenseRequirementsCompleted || false,
        order: 1
      } as ProfileAction,
      {
        displayName: 'Driving Experience',
        clickHandler: () => {
          this.registrationService.navigateToDrivingExperience(this.activatedRoute);
        },
        order: 2,
        isComplete: driverInfo.experienceRequirementsCompleted || false
      } as ProfileAction,
      {
        displayName: 'Accident Records',
        clickHandler: () => {
          this.registrationService.navigateToAccidentRecords(this.activatedRoute);
        },
        order: 3,
        isComplete: driverInfo.accidentsRequirementsCompleted || false
      } as ProfileAction,
      {
        displayName: 'Traffic Convictions',
        clickHandler: () => {
          this.registrationService.onNavigateToTrafficConvictions(this.activatedRoute);
        },
        order: 4,
        isComplete: driverInfo.convictionsRequirementsCompleted || false
      } as ProfileAction,
      {
        displayName: 'Employment History',
        clickHandler: () => {
          this.registrationService.navigateToEmploymentHistory(this.activatedRoute);
        },
        order: 5,
        isComplete: driverInfo.employmentRequirementsCompleted || false
      } as ProfileAction,
      {
        displayName: 'Residence History',
        clickHandler: () => {
          this.registrationService.navigateToResidenceHistory(this.activatedRoute);
        },
        order: 6,
        isComplete: driverInfo.residenceHistoryRequirementsCompleted || false
      } as ProfileAction
    ].sort((first, second) => first.order - second.order);
  }

  private loadAdditionalInformation(driverInfo: Driver): ProfileAction[] {
    return [
      {
        displayName: 'Documents',
        clickHandler: () => {
          this.registrationService.navigateToDocuments(this.activatedRoute);
        },
        order: 1,
        isComplete: this.checkAdditionalDocumentExist(driverInfo)
      } as ProfileAction,
      {
        displayName: 'Incorporation / WSIB',
        clickHandler: () => {
          this.registrationService.navigateToIncorporation(this.activatedRoute);
        },
        order: 2,
        isComplete: this.checkWorkPlaceSafetyExist(driverInfo)
      } as ProfileAction,
      {
        displayName: 'Education',
        clickHandler: () => {
          this.registrationService.navigateToEducation(this.activatedRoute);
        },
        order: 3,
        isComplete: this.checkEducationExist(driverInfo)
      } as ProfileAction,
      {
        displayName: 'Preferences',
        clickHandler: () => {
          this.registrationService.navigateToPreferences(this.activatedRoute);
        },
        order: 4,
        isComplete: this.checkPrefrenceExist(driverInfo)
      } as ProfileAction,
      {
        displayName: 'Insurance',
        clickHandler: () => {
          this.registrationService.navigateToInsurance(this.activatedRoute);
        },
        order: 5,
        isComplete: false
      } as ProfileAction
    ].sort((first, second) => first.order - second.order);
  }
  private checkAdditionalDocumentExist(driver: Driver): boolean {
    if (driver && driver?.notification) {
      const documents = driver.notification.filter(notificationModal => {
        return ADDITIONAL_DOCUMENTS.some((doc: any) => {
          return doc === notificationModal.type;
        });
      });
      // Look for visa doc based on license - country
      let visaDocument = [];
      const docType =
        this.licenseDetails?.issuingCountry === 'us'
          ? CANADA_VISA_DOCUMENT_TYPE
          : this.licenseDetails?.issuingCountry === 'ca'
          ? USVISA_DOCUMENT_TYPE
          : '';
      if (docType) {
        visaDocument = driver.notification.filter((doc: any) => {
          return doc.type === docType;
        });
      }

      return documents?.length === ADDITIONAL_DOCUMENTS.length && visaDocument.length > 0;
    }
  }
  private checkEducationExist(driver: Driver): boolean {
    return !!driver?.education;
  }
  private checkPrefrenceExist(driver: Driver): boolean {
    return Object.keys(driver?.preference).length !== 0;
  }
  private checkWorkPlaceSafetyExist(driver: Driver): boolean {
    return Object.keys(driver?.workplaceSafety).length !== 0;
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  isInComplete(status: string): boolean {
    return status == DQF_STATUS_CREATED || status == DQF_STATUS_PROCESSING;
  }
  isPending(status): boolean {
    return status == DQF_STATUS_PENDINGAPPROVAL || status == DQF_STATUS_DRIVER_SIGNATURE_SUBMITED;
  }
  isRejected(status): boolean {
    return status && status == DQF_STATUS_REJECTED;
  }
  isWithdrawn(status): boolean {
    return status && status == DQF_STATUS_WITHDRAWN;
  }
  isApproved(status): boolean {
    return (
      status &&
      [DQF_STATUS_APPROVED, DQF_STATUS_CARRIERPROCESSINGCOMPLETED, DQF_STATUS_APPROVEDPROCESSING].includes(status)
    );
  }

  navigateToDriverQualification() {
    this.frameNavigationService.navigateToDriverQualificaton();
  }

  onDqfItemClick(dqf): void {
    let id = dqf.id;
    if (dqf.status == DQF_STATUS_CREATED) {
      if (this.driverStatus === PROFILE_STATUS_INCOMPLETE) {
        let carrierName = dqf.carrierCompanyName;
        const dialogRef = this.bottomSheet.open(CompleteProfileSheetComponent, {
          panelClass: 'signature-panel',
          disableClose: true,
          data: { carrierName: carrierName }
        });
        dialogRef.afterDismissed().subscribe(res => {
          this.registrationService.navigateToProfile();
        });
      } else {
        let dqfStatus = {
          status: DQF_STATUS_PROCESSING
        };
        this.driverQualificationService.updateDqfStatus(id, dqfStatus).subscribe(res => {
          if (res && res.status === STATUS_CODE) {
            this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_DOCUMENT_UPLOAD}/${id}`]);
          }
        });
      }
    } else {
      if (this.isApproved(dqf.status) || dqf.status === DQF_STATUS_PENDINGAPPROVAL) {
        this.driverQualificationService.dqfData = dqf;
        this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_REVIEW}/${dqf.id}`]);
      } else {
        this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_DOCUMENT_UPLOAD}/${id}`]);
      }
    }
  }

  private checkIfDriverProfileIncomplete(): boolean {
    const { firstName, lastName, birthday, phoneNumber, gender, address, emergencyContacts } = this.registrationService.getDriver();
  
    if (!firstName || !lastName || !birthday || !phoneNumber || !gender || !address) {
      return true;
    }
  
    if (!emergencyContacts || emergencyContacts.length === 0) {
      return true;
    }
  
    return false;
  }
}
