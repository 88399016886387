<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
    <div class="header-navigation-back" fxFlayout>
      <div class="back-icon" (click)="backAction()" fxFlex="5" data-test="back-arrow-icon">
        <mat-icon svgIcon="left-arrow"></mat-icon>
      </div>
      <span fxFlex class="title">{{title}}</span>
    </div>
    <div class="content margin-top-md" fxLayout="column" [ngClass]="{ 'hide-content': !isContentReady }">
        <app-upload-document
        [formUploadFiles]="formUploadFiles"
        [documentDqfStatus]="true"
        [hideTabs]="true"
        (uploadDocument)="uploadDocument()"
        [documentType]="documentType"
        ></app-upload-document>
    </div>
 </div>