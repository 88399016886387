<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family employment-history">
  <div class="header-navigation-back">
    <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Employment History</span>
  </div>
  <div>
    <mat-error *ngIf="errorMessage" class="error padding-left-md">
      {{ errorMessage }}
    </mat-error>
  </div>
  <app-skeleton-emp-residence-history
    *ngIf="!isContentReady"
    isResidenceHistory="false"
  ></app-skeleton-emp-residence-history>
  <div *ngIf="isContentReady">
    <div [ngClass]="showHideAddButton() ? 'content' : 'content-overflow'" fxLayout="column" fxLayoutGap="15px">
      <div fxFlex="10" class="description-banner">
        <div class="description" *ngIf="showAddNewCurrentRecordText">
          <span class="light-font-weight"> Please update your current employment.</span>
        </div>
        <div class="description" *ngIf="!driver.employmentRequirementsCompleted && !showAddNewCurrentRecordText">
          <span class="light-font-weight"> Please provide your employment for the </span>
          <span class="bold-text">last 10 years</span><span class="light-font-weight">, without any gaps.</span>
        </div>
        <div class="description" *ngIf="driver.employmentRequirementsCompleted">
          <span class="light-font-weight"> Your employment details for the </span>
          <span class="bold-text">last 10 years</span>
        </div>
      </div>

      <div class="progress-bar-container">
        <mat-progress-bar mode="determinate" [value]="progressBarValue"></mat-progress-bar>
      </div>
      <div fxFlex="5" fxLayout class="progress-bar-text">
        <div fxFlex="50" class="past-date-text bold-text">{{ lastMonthYear }}</div>
        <div fxFlex="50" class="current-date-text bold-text">{{ currentMonthYear }}</div>
      </div>

      <div *ngIf="employmentList?.length > 0" class="app-employment-view">
        <div class="add-new">
          <p *ngIf="showAddNewCurrentRecordText" [matMenuTriggerFor]="addmenu">Add New Current Record</p>
          <mat-menu #addmenu="matMenu" class="button-menu-container" xPosition="after">
            <button mat-menu-item (click)="onAddNewCurrentEmployment(true)" class="menu-button">
              Employment Period
            </button>
            <button mat-menu-item (click)="onAddNewCurrentEmployment(false)" class="menu-button">
              Unemployment Period
            </button>
          </mat-menu>
        </div>
        <div *ngFor="let employment of employmentList; let index = index">
          <app-employment-view
            [model]="employment"
            [index]="index"
            [isAllowDeleteCurrentEmployment]="isAllowDeleteCurrentEmployment"
            (updateEmploymentPressed)="onUpdateEmploymentPressed($event)"
            (deleteEmploymentPressed)="onDeleteEmploymentPressed($event)"
            (openEmploymentFormPressed)="onAddNewEmployment($event)"
            (openUnEmploymentFormPressed)="onAddUnemployment($event)"
            class="app-employment-view"
          >
          </app-employment-view>
        </div>
      </div>
    </div>

    <div fxLayout class="add-new-container" *ngIf="showHideAddButton()">
      <div fxFlex="100" *ngIf="!isGreaterThan10years">
        <button mat-button [matMenuTriggerFor]="menu" class="btn-add">
          <i class="material-icons" data-test="add-new-record">add</i> Add New Record
        </button>
        <mat-menu #menu="matMenu" class="button-menu-container" xPosition="after">
          <button mat-menu-item (click)="onAddNewEmployment()" class="menu-button" data-test="employment-period">
            Employment Period
          </button>
          <button mat-menu-item (click)="onAddUnemployment()" class="menu-button" data-test="unemployment-period">Unemployment Period</button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
