import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/storage/storage.service';

const KEY_DRIVER = 'driver';

@Component({
  selector: 'app-dqf-driver-emergency-contact',
  templateUrl: './dqf-driver-emergency-contact.component.html',
  styleUrls: ['./dqf-driver-emergency-contact.component.scss']
})
export class DqfDriverEmergencyContactComponent implements OnInit {
  @Input() driverVersionDetails: any;
  data = [];
  constructor(private storageService: StorageService) {}

  ngOnInit(): void {
    this.getDriver();
  }

  private getDriver(): void {
    const driver = this.driverVersionDetails ?? this.storageService.find(KEY_DRIVER);
    const emergencyContact = driver.emergencyContacts;
    if (emergencyContact) {
      emergencyContact.forEach(contact => {
        this.data.push(
          {
            propertyName: 'Name',
            propertyValue: `${contact.emergencyContactName}`
          },
          { propertyName: 'Relation', propertyValue: `${contact.emergencyContactRelation}` },
          { propertyName: 'Phone', propertyValue: `${contact.emergencyContactPhone}` }
        );
      });
    }
  }
}
