import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationDialogComponent } from './information-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../../material/material.module';
import { ConfirmationDialogComponent } from '../confitmation-dialog/confirmation-dialog.component';

@NgModule({
  declarations: [InformationDialogComponent, ConfirmationDialogComponent],
  imports: [CommonModule, MatIconModule, FlexLayoutModule, MaterialModule],
  exports: [InformationDialogComponent, ConfirmationDialogComponent]
})
export class InformationDialogModule {}
