import { AccountLockedComponent } from './account-locked/account-locked.component';
import { SignupSuccessComponent } from './signup-success/signup-success.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import {
  ROUTE_SIGNUP,
  ROUTE_SIGNIN,
  ROUTE_SIGNUP_COMPLETED,
  ROUTE_SIGNUP_ERROR,
  ROUTE_ACCOUNT_LOCKED,
  ROUTE_TERMS_CONDITIONS,
  ROUTE_PRIVACY_STATEMENT,
  ROUTE_IDENTITY,
  ROUTE_IDENTITY_LAUNCH,
  ROUTE_SIGNUP_SUCCESS,
  ROUTE_ONBOARDING
} from './shared/routes';
import { SignUpCompletedComponent } from './sign-up-completed/sign-up-completed.component';
import { SignUpErrorComponent } from './sign-up-error/sign-up-error.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { IdentityStartComponent } from '../identity-check/identity-start/identity-start.component';
import { IdentityLaunchComponent } from '../identity-check/identity-launch/identity-launch.component';
import { OnboardingComponent } from '../onboarding/onboarding/onboarding.component';
import { ROUTE_CYPRESS_LICENSE_SCAN, ROUTE_LICENSE_UPLOAD } from '../new-license-scan/license-routes';
import { CypressLicenseScanComponent } from '../new-license-scan/cypress-license-scan/cypress-license-scan.component';
export const routes: Routes = [
  { path: ROUTE_SIGNUP, component: SignUpComponent },
  { path: ROUTE_SIGNUP_COMPLETED, component: SignUpCompletedComponent },
  { path: ROUTE_SIGNUP_SUCCESS, component: SignupSuccessComponent },
  { path: ROUTE_SIGNUP_ERROR, component: SignUpErrorComponent },
  { path: ROUTE_ACCOUNT_LOCKED, component: AccountLockedComponent },
  { path: ROUTE_SIGNIN, component: SignInComponent },
  { path: ROUTE_TERMS_CONDITIONS, component: TermsConditionsComponent },
  { path: ROUTE_PRIVACY_STATEMENT, component: PrivacyStatementComponent },
  { path: ROUTE_IDENTITY, component: IdentityStartComponent },
  { path: ROUTE_IDENTITY_LAUNCH, component: IdentityLaunchComponent },
  { path: ROUTE_ONBOARDING, component: OnboardingComponent },
  {
    path: ROUTE_CYPRESS_LICENSE_SCAN,
    component: CypressLicenseScanComponent 
  },
  {
    path: ROUTE_LICENSE_UPLOAD,
    component: CypressLicenseScanComponent 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
