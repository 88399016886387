import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import { Store } from '@ngxs/store';
import { RegistrationService } from '../shared/registration.service';
import { RegistrationState, NotificationStateModel } from '../state/registration.state';
import { map, catchError } from 'rxjs/operators';
import { DownloadDocument } from 'src/app/shared/models/download-document.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { of } from 'rxjs';

const ROUTE_PROFILE_DOCUMENT = 'profile/documents';
const LICENSE_DOCUMENT_TYPE = 'DriverLicense';
const PRCARD_DOCUMENT_TYPE = 'PRCard';
const TRAINING_CERTI_DOCUMENT = 'TrainingCertificate';
const PASSPORT_DOCUMENT_TYPE = 'Passport';
const CANADAIAN_VISA_DOCUMENT = 'CanadaVisa';
const US_VISA_DOCUMENT = 'USVisa';
const WORK_PERMIT_DOCUMENT = 'WorkPermit';
const DOCUMENT_TYPES = [
  LICENSE_DOCUMENT_TYPE,
  PRCARD_DOCUMENT_TYPE,
  TRAINING_CERTI_DOCUMENT,
  PASSPORT_DOCUMENT_TYPE,
  CANADAIAN_VISA_DOCUMENT,
  US_VISA_DOCUMENT,
  WORK_PERMIT_DOCUMENT
];
const ERROR_MESSAGE = 'Something Went Wrong.. please try again later';
const DELETED_DOCUMENT = 'Successfully deleted document';
@Component({
  selector: 'app-additional-document-view',
  templateUrl: './additional-document-view.component.html',
  styleUrls: ['./additional-document-view.component.scss']
})
export class AdditionalDocumentViewComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  loading;
  selectedType: string;
  selectedId: string;
  fileName: string = '';
  showError: boolean = false;
  errorMessage: string = ERROR_MESSAGE;
  generatedDate: string = '';
  expiryDate: string = '';

  constructor(
    private readonly location: Location,
    private route: ActivatedRoute,
    private readonly store: Store,
    private readonly registrationService: RegistrationService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => (this.selectedType = params.type));
    this.loadDriverDocuments();
  }
  backAction(): void {
    this.location.back();
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  onDownloadFileClick(id: string) {
    this.registrationService.downloadDocument(id).subscribe(response => {
      this.downloadDocument(response);
    });
  }
  downloadDocument(document: DownloadDocument) {
    if (document && document.fileUrl) {
      window.open(document.fileUrl);
    }
  }
  loadDriverDocuments(): void {
    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(map(() => this.store.selectSnapshot(RegistrationState.notification)))
        .subscribe((notificationStateModel: NotificationStateModel) => {
          this.loading = false;
          if (notificationStateModel && notificationStateModel.notifications) {
            const documents = notificationStateModel.notifications
              .filter(notification => notification.type === this.selectedType)
              .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
            if (documents?.length) {
              this.selectedId = documents[0]?.id;
              this.fileName = documents[0]?.typeDisplayName;
              this.generatedDate = documents[0]?.generationDate;
              this.expiryDate = documents[0]?.validToDate;
            }

            if (this.fileName == '') {
              this.showError = true;
            }
          }
        })
    );
  }

  deleteDocument(id: string): void {
    this.registrationService
      .deleteDocument(id)
      .pipe(
        catchError(() => {
          this.toastService.showError(ERROR_MESSAGE);
          return of(false);
        })
      )
      .subscribe(() => {
        this.toastService.showSuccess(DELETED_DOCUMENT);
        this.backAction();
      });
  }
}
