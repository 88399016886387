export const VIOLATION_TYPES = [
    { name: 'Stop Sign', value: 'stopSign' },
    { name: 'Speeding', value: 'speeding' },
    { name: 'License Type', value: 'licenseType' },
    { name: 'Loading', value: 'loading' },
    { name: 'Failed Inspection', value: 'failedInspection' },
    { name: 'Hours of Service (HoS)', value: 'hoursOfService' },
    { name: 'Adverse Driving', value: 'adverseDriving' },
    { name: 'Disobey Legal Sign', value: 'disobeyLegalSign' },
    { name: 'Failed To Wear Seat Belt', value: 'failedToWearSeatBelt' },
    { name: 'Failed To Show Driving License', value: 'failedToShowDrivingLicense' },
    { name: 'Improper Parking', value: 'improperParking' },
    { name: 'Other', value: 'other' },
  ];

