<ng-container *ngIf="data?.length">
  <div class="info-content" *ngFor="let info of data">
    <div class="heading">
      <label>{{ info.propertyName }}</label>
    </div>
    <div class="value">
      <p class="info">{{ info.propertyValue }}</p>
      <p class="info-sub" *ngIf="info.addressInfo">{{ info.addressInfo }}</p>
      <p
        class="info-sub"
        *ngIf="info.position && (info.position === 'Company Driver' || info.position === 'Owner Operator')"
      >
        {{ info.position }}
      </p>
      <p
        class="info-sub"
        *ngIf="info.position && info.position !== 'Company Driver' && info.position !== 'Owner Operator'"
      >
        Other: {{ info.position }}
      </p>
      <p class="info-sub" *ngIf="info?.licenseClass">{{ info?.licenseClass }}</p>
      <p class="info-sub">{{ info.subPropertyValue }}</p>
      <p class="info-sub" *ngIf="info?.assignedRadius">Assigned Radius: {{ info?.assignedRadius }}</p>
      <p class="info-sub" *ngIf="info?.equipmentType">Equipment Type(s): {{ info?.equipmentType }}</p>
      <p *ngIf="info?.reasonForLeaving" class="info-sub">Reason for leaving: {{ info?.reasonForLeaving }}</p>
      <div *ngIf="info?.referenceContactName">
        <div class="heading-sub">
          <label>REFERENCE CONTACT</label>
        </div>
        <div class="value">
          <p class="info-sub">{{ info?.referenceContactName }}</p>
          <p class="info-sub">{{ info?.referenceContactNumber }}</p>
          <p class="info-sub">{{ info?.referenceEmail }}</p>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</ng-container>
<div class="no-record" *ngIf="!data?.length">
  <p>No Records</p>
</div>
