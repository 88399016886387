import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { catchError } from 'rxjs/operators';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { MainTrafficConviction, TrafficConviction } from 'src/app/shared/models/traffic-convictions';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';

import { CANADA_PROVINCES, USA_PROVINCES } from 'src/app/shared/models/provinces';
import { VIOLATION_TYPES } from 'src/app/shared/models/traffic-violation-types';
import { PENALTY } from 'src/app/shared/models/traffic-penalty';
import { of } from 'rxjs';
const ACTION_SAVE_CONVICTIONS = 'Save Traffic Convictions';
const ACTION_UPDATE_CONVICTIONS = 'UPDATE Traffic Convictions';
const DELETE_MESSAGE = 'Are you sure you want to delete the Record?';
const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const DASH_NOT_ALLOW_AT_BEGINING = 'Dash and space is not allowed at begining';
const NUMBERS_SPEICAL_CHARACTERS_NOT_ALLOWED = 'Only alphabets, hyphen, apostrophe and space are allowed';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@Component({
  selector: 'app-traffic-convictions',
  templateUrl: './traffic-convictions.component.html',
  styleUrls: ['./traffic-convictions.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class TrafficConvictionsComponent implements OnInit {
  REQUIRED_FIELD_MESSAGE = 'You must enter a value';
  openForm = false;
  errorMessage: string;
  noTrafficConvictionsRecordsForm: FormGroup;
  trafficConvictionForm: FormGroup;
  disableNoTrafficConvictions: boolean;
  violationTypes = VIOLATION_TYPES;
  penalty = PENALTY;
  demeritPoints = [0, 1, 2, 3, 4, 5];
  maxDate = new Date();
  disableAddNewRecord = false;
  stateOfViolation = CANADA_PROVINCES.concat(USA_PROVINCES);
  isContentReady: boolean = false;
  private subs = new SubSink();
  trafficRecords: TrafficConviction[];
  noConvinctionRecord: MainTrafficConviction;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly location: Location,
    private readonly driverApiService: DriverApiService,
    private readonly timeService: DateTimeService,
    private readonly fb: FormBuilder
  ) {
    this.errorMessage = undefined;
    this.trafficConvictionForm = this.createForm();
    this.noTrafficConvictionsRecordsForm = this.createNoTrafficConvictionsRecordsForm();
  }

  ngOnInit(): void {
    this.loadTrafficConvictionsRecords();
    this.noTrafficConvictionsRecordsForm.get('noConvictions').valueChanges.subscribe(value => {
      setTimeout(() => {
        if (value !== this.noConvinctionRecord?.noConvictions) {
          this.onSubmitTrafficConvictionRecord(true).then(() => {
            if (value) {
              this.location.back();
            }
          });
        }
        if (!this.noConvinctionRecord?.noConvictions) {
          if (value) {
            this.disableAddNewRecord = true;
          } else {
            this.disableAddNewRecord = false;
          }
        }
      }, 0);
    });
    this.onViolationTypeChanged();
  }

  onViolationTypeChanged() {
    this.trafficConvictionForm.get('violationType').valueChanges.subscribe(value => {
      this.updateValidators();
    });
  }

  updateValidators() {
    const vilationTypeValue = this.trafficConvictionForm.get('violationType')?.value;
    const otherViolationTypeControl = this.trafficConvictionForm.get('otherViolationType');

    if (vilationTypeValue === 'other') {
      otherViolationTypeControl.setValidators([Validators.required, Validators.pattern("^[A-Za-z-'\\s]+$")]);
      otherViolationTypeControl.setValue('');
    } else {
      otherViolationTypeControl.clearValidators();
      otherViolationTypeControl.setValue('');
    }
    otherViolationTypeControl.updateValueAndValidity();
  }

  loadTrafficConvictionsRecords(): void {
    this.isContentReady = false;
    this.trafficRecords = [];
    this.subs.add(
      this.registrationService.getTrafficConvictions().subscribe((response: any) => {
        if (response?.length) {
          response.forEach(res => {
            if (!res.hasOwnProperty('noConvictions')) {
              this.trafficRecords.push(res);
            } else {
              this.noConvinctionRecord = res;
            }
          });
          if (this.noConvinctionRecord) {
            this.noTrafficConvictionsRecordsForm.patchValue(this.noConvinctionRecord);
            this.disableAddNewRecord =
              this.noConvinctionRecord && this.noConvinctionRecord.noConvictions ? true : false;
          }
          if (this.trafficRecords && this.trafficRecords.length > 0) {
            this.disableNoTrafficConvictions = true;
          } else {
            this.disableNoTrafficConvictions = false;
          }
        }
        this.isContentReady = true;
      })
    );
  }

  onAddNewTrafficConvictionRecord(): void {
    this.openForm = true;
    this.disableAddNewRecord = true;
    this.disableNoTrafficConvictions = true;
  }

  onCancel(): void {
    this.openForm = false;
    this.disableNoTrafficConvictions = false;
    this.disableAddNewRecord = false;
    this.trafficConvictionForm.reset();
  }

  goBack(): void {
    this.location.back();
  }

  onSubmitTrafficConvictionRecord(value: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.isContentReady = false;
      const model = this.prepareModel(value);
      if (model && model.id == null) {
        delete model.id;
        this.subs.add(
          this.registrationService
            .saveTrafficConviction(model)
            .pipe(
              catchError((error: any) => {
                this.errorMessage = environment.errorMessage;
                this.isContentReady = true;
                reject();
                return of(false);
              })
            )
            .subscribe((response: any) => {
              this.errorMessage = undefined;
              this.disableAddNewRecord = false;
              this.openForm = false;
              this.loadTrafficConvictionsRecords();
              this.trafficConvictionForm.reset();
              this.isContentReady = true;
              resolve();
            })
        );
      } else {
        let convictionId = model.id;
        delete model.id;
        this.subs.add(
          this.registrationService
            .updateTrafficConviction(model, convictionId)
            .pipe(
              catchError((error: any) => {
                this.errorMessage = environment.errorMessage;
                this.isContentReady = true;
                reject();
                return of(false);
              })
            )
            .subscribe((response: any) => {
              this.errorMessage = undefined;
              this.disableAddNewRecord = false;
              this.openForm = false;
              this.loadTrafficConvictionsRecords();
              this.trafficConvictionForm.reset();
              this.isContentReady = true;
              resolve();
            })
        );
      }
    });
  }

  prepareModel(value): MainTrafficConviction | TrafficConviction {
    if (value) {
      return new MainTrafficConviction(this.noTrafficConvictionsRecordsForm.value);
    } else {
      return new TrafficConviction(this.trafficConvictionForm.value);
    }
  }

  onEdit(record: TrafficConviction): void {
    this.onAddNewTrafficConvictionRecord();
    this.populateForm(record);
    this.disableAddNewRecord = true;
    this.trafficConvictionForm.markAsDirty();
  }

  onDelete(record: TrafficConviction): void {
    if (confirm(DELETE_MESSAGE)) {
      this.isContentReady = false;
      this.registrationService
        .deleteTrafficConviction(record.id)
        .pipe(
          catchError((error: any) => {
            this.errorMessage = environment.errorMessage;
            this.isContentReady = true;
            return of(false);
          })
        )
        .subscribe(response => {
          this.loadTrafficConvictionsRecords();
        });
    }
  }
  getViolationValue(value): string {
    const foundType = this.violationTypes.find(type => type.value === value);
    return foundType ? foundType.name : 'Other';
  }

  getOtherViolationTypeErrorMessage() {
    const errors = this.trafficConvictionForm.get('violationType').errors;
    if (errors) {
      if (errors.required) {
        return REQUIRED_FIELD_MESSAGE;
      }
      if (errors.pattern) {
        const specialCharactersAtBeginig = new RegExp(/^[-'\s]/);
        if (this.trafficConvictionForm.get('violationType').value.match(specialCharactersAtBeginig)) {
          return DASH_NOT_ALLOW_AT_BEGINING;
        } else {
          return NUMBERS_SPEICAL_CHARACTERS_NOT_ALLOWED;
        }
      }
    }
    return errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  private createNoTrafficConvictionsRecordsForm(): FormGroup {
    const form = this.fb.group({
      id: [''],
      noConvictions: [false]
    });
    return form;
  }

  private createForm(): FormGroup {
    return this.fb.group({
      id: '',
      convictionDate: ['', Validators.required],
      violationType: ['', Validators.required],
      otherViolationType: [''],
      stateOfViolation: ['', Validators.required],
      penalty: ['', Validators.required],
      demeritPoints: ['', Validators.required]
    });
  }

  private populateForm(record: TrafficConviction): void {
    const recordedViolationType = VIOLATION_TYPES.find(v => v.value === record.violationType);

    this.trafficConvictionForm.patchValue({
      id: record?.id,
      convictionDate: record.convictionDate,
      violationType: recordedViolationType ? record.violationType : 'other',
      otherViolationType: recordedViolationType ? '' : record.violationType,
      stateOfViolation: record.stateOfViolation,
      penalty: record.penalty,
      demeritPoints: record.demeritPoints
    })
  }
}
