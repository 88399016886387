import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DriverQualificationService } from '../service/driver-qualification.service';
import { DqfDocument } from '../models/dqfDocument';
import { ROUTE_DQF, ROUTE_ON_DUTY_HOURS, ROUTE_DQF_REVIEW, ROUTE_DQF_UPLOADS } from 'src/app/shared/routes';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { CARRIER_UPLOAD_REQUESTED } from 'src/app/shared/constants';
import {
  DRIVER_DOCS_ARRAY,
  CARRIER_DOC_ARRAY,
  DOCTYPE_ACCIDENT_HISTORY,
  DOCTYPE_DRIVING_EXPERIENCE,
  DOCTYPE_EMP_HISTORY,
  DOCTYPE_LICENSE_TYPE,
  DOCTYPE_ON_DUTY_HOURS,
  DOCTYPE_PERSONAL_INFO,
  DOCTYPE_RESIDENCE_HISTORY,
  DOCTYPE_TRAFFIC_CONVICTION,
  ALL_DRIVER_DOCS_ARRAY,
  DRIVER_DOC_CONTEXT_INITIAL_DQF
} from '../constants';
import { ToastService } from 'src/app/shared/toast/toast.service';
import dayjs from 'dayjs';
import { Notification } from 'src/app/shared/models/notification.model';

const DQF_STATUS_PROCESSING = 'Processing';
const STATUS_REJECTED = 'Rejected';
const STATUS_PENDING = 'Pending';
const DOC_STATUS_DRIVER_REVIEWED = 'DriverReviewed';
const DOCUMENT_REQUESTED = 'CarrierUploadRequested';
const DRIVER_UPLOADS = 'Driver Uploads';
const CARRIER_UPLOADS = 'Carrier Uploads';
const EXPIRED_LICENSE_WARNING = 'Enter valid license to move forward';
const DOCUMENT_TYPE_SIGN = 'declarationForm';
const EMPLOYMENT_HISTORY_CHECK = 'EmploymentHistoryCheck';
const DOCUMENT_SIGN_DISPLAY_NAME = 'Declaration';
const EMPLOYMENT_HISTORY_CHECK_DISPLAY_NAME = 'Employment History Check';
const ACTOR_TYPE_DRIVER = 'driver';
const PENDING_STATUS = 'Pending';
const SIGNED_STATUS = 'signed';
const UPLOADED_STATUS = 'uploaded';

const UPDATE_DQF_DOC_MODEL = {
  attachedDocument: {
    type: '',
    status: DOC_STATUS_DRIVER_REVIEWED
  }
};
export interface DocumentAction {
  status: string;
  displayName?: string;
  iconName?: string;
  type: string;
  rejectionReason?: string;
  isRequirementsCompleted: boolean;
  clickHandler?: (status: string) => void;
  order?: number;
  uploaded?: number;
  hasIcon?: boolean;
  id?: string;
  isRejected?: boolean;
  action?: string;
  licenseProvince?: string;
}
@Component({
  selector: 'app-dqf-document-upload',
  templateUrl: './dqf-document-upload.component.html',
  styleUrls: ['./dqf-document-upload.component.scss']
})
export class DqfDocumentUploadComponent implements OnInit {
  dqfId: string;
  dqfData: DqfDocument;
  clubbedDocuments: DocumentAction[] = [];
  personalInfoStatus: string;
  drugAlocoholStatus: string;
  trainingCertiStatus: string;
  drivingAbstract: string;
  empHistoryStatus: string;
  drivingExperienceStatus: string;
  drivingLicenseStatus: string;
  residenceHistoryStatus: string;
  accidentHistoryStatus: string;
  trafficHistoryStatus: string;
  onDutyStatus: string;
  abstractStatus: string;

  isPersonalInfoStatus: boolean = false;
  isDrugAlcoholPresent: boolean = false;
  isTrainingCerti: boolean = false;
  isDrivingAbstract: boolean = false;
  isDrivingExperience: boolean = false;
  isEmpHistory: boolean = false;
  isAllowToSign: boolean = false;
  isReview = false;
  dqfAttachments = [];
  driverUploadDocuments = ALL_DRIVER_DOCS_ARRAY;
  carrierUploadDocuments = CARRIER_DOC_ARRAY;
  licenseProvince = null;
  carrierAttachedDocuments;
  backImageUploadedDate: any;
  frontImageUploadedDate: any;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly router: Router,
    private readonly registrationService: RegistrationService,
    private readonly driverQualificationService: DriverQualificationService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.dqfId = this.dqfId ? this.dqfId : this.route.snapshot.paramMap.get('id');
    this.driverQualificationService.dqfId = this.dqfId;
    this.getLicense();
    this.findUnSignedDocumentsAndGetHelloSignUrls();
  }

  getDQF(): void {
    this.driverQualificationService.getDqfById(this.dqfId).subscribe(res => {
      if (res) {
        this.dqfData = res;
        this.carrierAttachedDocuments = this.dqfData?.carrierAttachedDocuments?.length;
        this.driverQualificationService.dqfData = res;
        if (this.dqfData?.attachedDocuments?.length > 0) {
          this.dqfAttachments = [...this.dqfData.attachedDocuments];
          const attachments = this.clubAttachDocuments(this.dqfAttachments);
          this.addDriverAndCarrierFolder(attachments);
          this.clubbedDocuments = attachments;
          if (this.dqfData.status == DQF_STATUS_PROCESSING) {
            this.isReview = this.checkIsDqfIsReadyToReview();
          }
          if (this.dqfData.status == STATUS_REJECTED) {
            let documentCount = 0;
            this.dqfData.attachedDocuments.forEach(doc => {
              if (!(doc.status == STATUS_REJECTED || doc.status == STATUS_PENDING)) {
                documentCount++;
              }
            });
            if (documentCount == this.dqfData.attachedDocuments.length) {
              this.isReview = true;
            }
          }
        }
      }
    });
  }

  findUnSignedDocumentsAndGetHelloSignUrls() {
    const declarationModel = {
      actorType: ACTOR_TYPE_DRIVER,
      documentDisplayName: '',
      documentType: '',
      context: DRIVER_DOC_CONTEXT_INITIAL_DQF,
      dqfId: this.dqfId
    };

    let notifications = this.registrationService.getDriver().notification;
    if (notifications.length) {
      declarationModel.documentType = DOCUMENT_TYPE_SIGN;
      declarationModel.documentDisplayName = DOCUMENT_SIGN_DISPLAY_NAME;
      this.checkDocumentSigningRequired(notifications, declarationModel);
      declarationModel.documentType = EMPLOYMENT_HISTORY_CHECK;
      declarationModel.documentDisplayName = EMPLOYMENT_HISTORY_CHECK_DISPLAY_NAME;
      this.checkDocumentSigningRequired(notifications, declarationModel);
    } else {
      declarationModel.documentType = DOCUMENT_TYPE_SIGN;
      declarationModel.documentDisplayName = DOCUMENT_SIGN_DISPLAY_NAME;
      this.getHelloSignUrls(declarationModel);
      declarationModel.documentType = EMPLOYMENT_HISTORY_CHECK;
      declarationModel.documentDisplayName = EMPLOYMENT_HISTORY_CHECK_DISPLAY_NAME;
      this.getHelloSignUrls(declarationModel);
    }
  }

  private checkDocumentSigningRequired(notifications: Notification[], document: any): void {
    const filteredNotifications = notifications.filter(
      notification =>
        notification.type === document.documentType && notification?.dqfId === this.driverQualificationService.dqfId
    );

    if (filteredNotifications.length) {
      const hasUploadedStatus = filteredNotifications.some(
        notification => notification.status === UPLOADED_STATUS || notification.status === SIGNED_STATUS
      );
  
      if (!hasUploadedStatus) {
        this.getHelloSignUrls(document);
      }
    } else {
      this.getHelloSignUrls(document);
    }
  }

  private getHelloSignUrls(document: any): void {
    this.driverQualificationService.getHelloSignUrl(document).subscribe();
  }

  checkIsDqfIsReadyToReview(): boolean {
    let isPresent = 0;
    this.dqfData.attachedDocuments.forEach(doc => {
      if (doc.isRequirementsCompleted && doc.status === DOC_STATUS_DRIVER_REVIEWED) {
        isPresent++;
      }
      if (doc.status === DOCUMENT_REQUESTED) {
        isPresent++;
      }
    });

    return isPresent === this.dqfData.attachedDocuments?.length;
  }

  goToProfileInfo(doc?: any): void {
    if (doc.status == STATUS_REJECTED || doc.status == STATUS_PENDING) {
      if (doc.isRequirementsCompleted) {
        UPDATE_DQF_DOC_MODEL.attachedDocument.type = doc.type;
        this.driverQualificationService.updateDQFStatusToReview(doc, UPDATE_DQF_DOC_MODEL, () =>
          this.registrationService.navigateToPersonalInformation(this.route)
        );
      } else {
        this.registrationService.navigateToPersonalInformation(this.route);
      }
    } else {
      this.registrationService.navigateToPersonalInformation(this.route);
    }
  }
  goToLicenseInfo(doc?: any): void {
    if (doc.status == STATUS_REJECTED || doc.status == STATUS_PENDING) {
      if (doc.isRequirementsCompleted) {
        UPDATE_DQF_DOC_MODEL.attachedDocument.type = doc.type;
        this.driverQualificationService.updateDQFStatusToReview(doc, UPDATE_DQF_DOC_MODEL, () =>
          this.registrationService.onNavigateToLicense(this.route)
        );
      } else {
        this.registrationService.onNavigateToLicense(this.route);
      }
    } else {
      this.registrationService.onNavigateToLicense(this.route);
    }
  }
  goToResidenceHistory(doc?: any): void {
    if (doc.status == STATUS_REJECTED || doc.status == STATUS_PENDING) {
      if (doc.isRequirementsCompleted) {
        UPDATE_DQF_DOC_MODEL.attachedDocument.type = doc.type;
        this.driverQualificationService.updateDQFStatusToReview(doc, UPDATE_DQF_DOC_MODEL, () =>
          this.registrationService.navigateToResidenceHistory(this.route)
        );
      } else {
        this.registrationService.navigateToResidenceHistory(this.route);
      }
    } else {
      this.registrationService.navigateToResidenceHistory(this.route);
    }
  }
  goToDrivingExperience(doc?: any): void {
    if (doc.status == STATUS_REJECTED || doc.status == STATUS_PENDING) {
      if (doc.isRequirementsCompleted) {
        UPDATE_DQF_DOC_MODEL.attachedDocument.type = doc.type;
        this.driverQualificationService.updateDQFStatusToReview(doc, UPDATE_DQF_DOC_MODEL, () =>
          this.registrationService.navigateToDrivingExperience(this.route)
        );
      } else {
        this.registrationService.navigateToDrivingExperience(this.route);
      }
    } else {
      this.registrationService.navigateToDrivingExperience(this.route);
    }
  }
  goToEmpHistory(doc?: any): void {
    if (doc.status == STATUS_REJECTED || doc.status == STATUS_PENDING) {
      if (doc.isRequirementsCompleted) {
        UPDATE_DQF_DOC_MODEL.attachedDocument.type = doc.type;
        this.driverQualificationService.updateDQFStatusToReview(doc, UPDATE_DQF_DOC_MODEL, () =>
          this.registrationService.navigateToEmploymentHistory(this.route)
        );
      } else {
        this.registrationService.navigateToEmploymentHistory(this.route);
      }
    } else {
      this.registrationService.navigateToEmploymentHistory(this.route);
    }
  }
  goToAccidentRecords(doc?: any): void {
    if (doc.status == STATUS_REJECTED || doc.status == STATUS_PENDING) {
      if (doc.isRequirementsCompleted) {
        UPDATE_DQF_DOC_MODEL.attachedDocument.type = doc.type;
        this.driverQualificationService.updateDQFStatusToReview(doc, UPDATE_DQF_DOC_MODEL, () =>
          this.registrationService.navigateToAccidentRecords(this.route)
        );
      } else {
        this.registrationService.navigateToAccidentRecords(this.route);
      }
    } else {
      this.registrationService.navigateToAccidentRecords(this.route);
    }
  }
  goToTrafficConviction(doc?: any): void {
    if (doc.status == STATUS_REJECTED || doc.status == STATUS_PENDING) {
      if (doc.isRequirementsCompleted) {
        UPDATE_DQF_DOC_MODEL.attachedDocument.type = doc.type;
        this.driverQualificationService.updateDQFStatusToReview(doc, UPDATE_DQF_DOC_MODEL, () =>
          this.registrationService.onNavigateToTrafficConvictions(this.route)
        );
      } else {
        this.registrationService.onNavigateToTrafficConvictions(this.route);
      }
    } else {
      this.registrationService.onNavigateToTrafficConvictions(this.route);
    }
  }

  goToOnHourDuty(doc?: any): void {
    if (doc.status == STATUS_REJECTED || doc.status == STATUS_PENDING) {
      if (doc.isRequirementsCompleted) {
        UPDATE_DQF_DOC_MODEL.attachedDocument.type = doc.type;
        this.driverQualificationService.updateDQFStatusToReview(doc, UPDATE_DQF_DOC_MODEL, () =>
          this.navigateToOnDutyHours()
        );
      } else {
        this.navigateToOnDutyHours();
      }
    } else {
      this.navigateToOnDutyHours();
    }
  }

  backAction() {
    this.location.back();
  }

  onReview(): void {
    if (this.isLicenseValid()) {
      this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_REVIEW}/${this.dqfId}`]);
    } else {
      this.toastService.showWarning(EXPIRED_LICENSE_WARNING);
    }
  }
  isLicenseValid(): boolean {
    const licenseInfo = this.registrationService.getLicenseStore();

    return dayjs(licenseInfo.expiryDate).isAfter(dayjs(), 'day');
  }

  private addDriverAndCarrierFolder(attachedDocument: DocumentAction[]) {
    this.addToAttachment(DRIVER_UPLOADS, this.goToUploadRecords.bind(this), attachedDocument);
    this.addToAttachment(CARRIER_UPLOADS, this.goToUploadRecords.bind(this), attachedDocument);
  }

  private addToAttachment(displayName: string, onClickEvent: any, attachedDocument: DocumentAction[]): void {
    let uploadedCount = 0;
    let rejectedCount = 0;
    if (displayName === DRIVER_UPLOADS) {
      uploadedCount = this.dqfData?.attachedDocuments?.filter(
        e =>
          (e.isRequirementsCompleted || e.status === CARRIER_UPLOAD_REQUESTED) &&
          this.driverUploadDocuments.some(type => type.toLowerCase() === e.type.toLowerCase())
      )?.length;

      rejectedCount = this.dqfData?.attachedDocuments?.filter(
        e =>
          e.isRequirementsCompleted &&
          e.status === STATUS_REJECTED &&
          this.driverUploadDocuments.some(type => type.toLowerCase() === e.type.toLowerCase())
      )?.length;
    } else {
      uploadedCount = this.dqfData?.carrierAttachedDocuments?.filter(
        e => e.id && this.carrierUploadDocuments.some(type => type.toLowerCase() === e.type.toLowerCase())
      )?.length;
    }

    const upload: DocumentAction = {
      status: null,
      type: '',
      rejectionReason: '',
      isRequirementsCompleted: null,
      iconName: 'folder-icon',
      displayName: displayName,
      uploaded: uploadedCount || 0,
      hasIcon: false,
      isRejected: rejectedCount ? true : false,
      licenseProvince: this.licenseProvince,
      clickHandler: () => {
        onClickEvent(upload);
      }
    };
    attachedDocument.push(upload);
  }

  private goToUploadRecords(documentData: DocumentAction): void {
    this.router.navigateByUrl(`${ROUTE_DQF}/${ROUTE_DQF_UPLOADS}?type=` + documentData.displayName);
  }

  private clubAttachDocuments(attachedDocuments): DocumentAction[] {
    let documents: DocumentAction[] = [];
    attachedDocuments.forEach(doc => {
      let info: DocumentAction = {
        status: doc.status,
        type: doc.type,
        rejectionReason: doc.rejectionReason,
        isRequirementsCompleted: doc.isRequirementsCompleted,
        licenseProvince: this.licenseProvince
      };

      if (info.type == DOCTYPE_PERSONAL_INFO) {
        info.iconName = 'profile-info-icon';
        info.displayName = 'Personal Information';
        info.clickHandler = () => {
          this.goToProfileInfo(info);
        };
        info.order = 1;
        documents.push(info);
        return;
      }
      if (info.type == DOCTYPE_LICENSE_TYPE) {
        info.iconName = 'license-info-icon';
        info.displayName = 'License information';
        info.clickHandler = () => {
          this.goToLicenseInfo(info);
        };
        info.order = 2;
        documents.push(info);
        return;
      }
      if (info.type == DOCTYPE_RESIDENCE_HISTORY) {
        info.iconName = 'residence-icon';
        info.displayName = 'Residence History';
        info.clickHandler = () => {
          this.goToResidenceHistory(info);
        };
        info.order = 3;
        documents.push(info);
        return;
      }
      if (info.type == DOCTYPE_DRIVING_EXPERIENCE) {
        info.iconName = 'driving-exp-icon';
        info.displayName = 'Driving Experience';
        info.clickHandler = () => {
          this.goToDrivingExperience(info);
        };
        info.order = 4;
        documents.push(info);
        return;
      }
      if (info.type == DOCTYPE_EMP_HISTORY) {
        info.iconName = 'emp-history-icon';
        info.displayName = 'Employment History';
        info.clickHandler = () => {
          this.goToEmpHistory(info);
        };
        info.order = 5;
        documents.push(info);
        return;
      }
      if (info.type == DOCTYPE_ACCIDENT_HISTORY) {
        info.iconName = 'accident-record-icon';
        info.displayName = 'Accident Records';
        info.clickHandler = () => {
          this.goToAccidentRecords(info);
        };
        info.order = 6;
        documents.push(info);
        return;
      }
      if (info.type == DOCTYPE_TRAFFIC_CONVICTION) {
        info.iconName = 'accident-record-icon';
        info.displayName = 'Traffic Convictions';
        info.clickHandler = () => {
          this.goToTrafficConviction(info);
        };
        info.order = 7;
        documents.push(info);
        return;
      }
      if (info.type == DOCTYPE_ON_DUTY_HOURS) {
        info.iconName = 'on-duty-icon';
        info.displayName = 'On-Duty hours';
        info.clickHandler = () => {
          this.goToOnHourDuty(info);
        };
        info.order = 8;
        documents.push(info);
        return;
      }
    });
    return documents.sort((first, second) => first.order - second.order);
  }

  private navigateToOnDutyHours(): void {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_ON_DUTY_HOURS}`]);
  }

  private getLicense(): void {
    const license = this.registrationService.getLicenseStore();
    if (license) {
      this.licenseProvince = license.issuingProvince?.toLowerCase();
      this.frontImageUploadedDate = license.frontImageUploadedDate;
      this.backImageUploadedDate = license.backImageUploadedDate;
    }
    this.getDQF();
  }
}
