<div class="header-navigation-back">
    <mat-icon (click)="onNavigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Filter Shipments</span>
</div>
<div class="filter-content">
    <form [formGroup]="filterForm">
        <!-- Availability From -->
        <div>
            <mat-label>Pickup from</mat-label>
            <mat-form-field appearance="outline">
                <input matInput readonly [matDatepicker]="fromDate" placeholder="DD-MM-YYYY"
                 (click)="fromDate.open()" formControlName="fromDate"  (dateChange)="onDateChangeEvent($event, 'fromDate')" />
                <mat-datepicker-toggle matSuffix [for]="fromDate" data-test="from-date-form-field"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #fromDate></mat-datepicker>
            </mat-form-field>
        </div>
         <!-- Availability To -->
         <div>
            <mat-label>Pickup to</mat-label>
            <mat-form-field appearance="outline">
                <input 
                 matInput 
                 readonly 
                 [matDatepicker]="toDate" 
                 placeholder="DD-MM-YYYY"
                 formControlName="toDate" 
                 (dateChange)="onDateChangeEvent($event, 'toDate')"
                 [min]="filterForm.get('fromDate')?.value"
                 (click)="toDate.open()" 
                />
                <mat-datepicker-toggle matSuffix [for]="toDate" data-test="to-date-form-field"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #toDate></mat-datepicker>
            </mat-form-field>
        </div>
         <!-- Status -->
         <div>
            <mat-label>Status</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="status" placeholder="Status" readonly (click)="onOpenStatusActionSheet()" data-test="status-field"/>
            </mat-form-field>
        </div>
         <!-- Pickup -->
         <div>
            <mat-label>Pick Up</mat-label>
            <mat-form-field class="full-width" appearance="outline">
                <input matInput #pickUpText placeholder="Enter pickup" formControlName="pickUp" 
                [value]="pickUp" data-test="pickUp-form-field" name="pickUp" (change)="onAddressChange($event, 'pickUp')"/>
            </mat-form-field>
        </div>
         <!-- Drop -->
         <div>
            <mat-label>Drop Off</mat-label>
            <mat-form-field class="full-width" appearance="outline">
                <input matInput #dropText placeholder="Enter drop off" formControlName="dropOff" [value]="dropOff" name="dropOff"
                data-test="drop-form-field" (change)="onAddressChange($event, 'dropOff')"/>
            </mat-form-field>
        </div>
        <!-- Load Size -->
        <div>
            <mat-label>Load Size</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="loadSize" placeholder="Load Size" readonly (click)="onOpenLoadSizeActionSheet()" data-test="load-size-field"/>
            </mat-form-field>
        </div>
        <div class="margin-top-md">
            <button mat-button class="btn-accept" [disabled]="!filterForm.valid" type="button" (click)="onApplyFilters()" data-test="apply-button">Apply</button>
        </div>
        <div class="margin-bottom-lg margin-top-md">
            <button mat-button class="btn-clear" (click)="onClearFilters()" data-test="clear-filter-button">Clear filters</button>
        </div>
    </form>
</div>