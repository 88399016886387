import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DriverQualificationService } from '../service/driver-qualification.service';
import { Location } from '@angular/common';
import { CRIMINAL_OFFENSE } from 'src/app/shared/models/criminal-offense.model';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import dayjs from 'dayjs';
import { ACTION_TYPE_COLLECT, DQF_DOC_STATUS, POLICE_CLEARANCE } from '../constants';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Router } from '@angular/router';
import { ROUTE_DQF } from '../driverQualification-routing.module';
import { ROUTE_DQF_BACKGROUND_CHECK_PROCESSING } from 'src/app/shared/routes';
import { of } from 'rxjs';
import { Action } from 'src/app/jobs-shared/job-data/models/action';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JobConfirmationDialogComponent } from 'src/app/jobs-shared/job-confirmation-dialog/job-confirmation-dialog.component';
import { InformationDialogComponent } from 'src/app/shared/dialog/information-dialog/information-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confitmation-dialog/confirmation-dialog.component';

const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const ACTION_ADD_CRIMINAL_CONVICTIONS = 'Add criminal convictions';
const ADDED_RECORD = 'Successfully added record';
const REGX_DESCRIPTION = /^([a-zA-z-,&0-9]{1,60})/;
const DOCUMENT_TYPE = 'Police Clearance';
const BACKGROUND_PROCESSING_INFO = 'Criminal Record Check';
const REQUEST_SUCCESS = 'Successfully sent the request';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-dqf-criminal-convictions',
  templateUrl: './dqf-criminal-convictions.component.html',
  styleUrls: ['./dqf-criminal-convictions.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DqfCriminalConvictionsComponent implements OnInit {
  maxDate = dayjs();
  criminalOffense = CRIMINAL_OFFENSE;
  criminalConvictionForm: FormGroup;
  showConvictionToAdd = false;
  requiredMessage = REQUIRED_FIELD_MESSAGE;
  convictionsData = [];
  showAddedConvictions = false;
  editRecordIndex = -1;
  errorMessage: string;
  noConvictions = null;
  loading = false;
  @ViewChild('courtLocation', { static: false }) courtLocation: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly dqfService: DriverQualificationService,
    private readonly zone: NgZone,
    private readonly driverApiService: DriverApiService,
    private readonly dateTimeService: DateTimeService,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.convictionsData = this.dqfService.driverBirthLocationData?.reportData?.convictions;
    this.criminalConvictionForm = this.fb.group({
      convictionOffense: new FormControl('', Validators.required),
      dateOfSentence: new FormControl('', Validators.required),
      courtLocation: new FormControl('', Validators.required),
      description: new FormControl('', [Validators.required, Validators.pattern(REGX_DESCRIPTION)])
    });
  }

  onChange(value): void {
    if (value === 'yes') {
      this.showConvictionToAdd = true;
      this.noConvictions = null;
    } else {
      this.noConvictions = value;
      this.showConvictionToAdd = false;
    }
  }

  getLocation() {
    this.getPlaceAutocomplete(this.courtLocation);
  }

  addConviction(isAddMore: boolean): void {
    if (isAddMore) {
      this.criminalConvictionForm.reset();
      this.showAddedConvictions = !this.showAddedConvictions;
      this.showConvictionToAdd = true;
    } else {
      if (this.criminalConvictionForm.valid) {
        const data = {
          offense: this.criminalConvictionForm.value.convictionOffense,
          sentenceDate: dayjs(this.criminalConvictionForm.value.dateOfSentence).format('YYYY-MM-DD') as string,
          courtLocation: this.criminalConvictionForm.value.courtLocation,
          description: this.criminalConvictionForm.value.description
        };
        if (this.editRecordIndex >= 0) {
          this.onDeleteConviction(this.editRecordIndex);
          this.onCancel();
        }
        this.dqfService.driverBirthLocationData?.reportData?.convictions?.push(data);
        this.showAddedConvictions = !this.showAddedConvictions;
        this.criminalConvictionForm.reset();
      } else {
        this.criminalConvictionForm.markAllAsTouched();
        return;
      }
    }
  }

  showCompleteJobConfirmation(): void {
    const actions = this.createAcceptDialogActions();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: 'Proceed to order PCC?',
        message: 'This action cannot be undone.',
        actions
      },
      disableClose: true
    });
    const confirmAction = actions[1];
    const cancelAction = actions[0];
    confirmAction.action = async () => this.saveConviction(dialogRef, confirmAction, cancelAction);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef.afterClosed().subscribe();
  }

  saveConviction(dialogRef, confirmAction, cancelAction): void {
    this.loading = true;
    confirmAction.disabled = true;
    cancelAction.disabled = true;
    const model = {
      attachedDocument: {
        type: POLICE_CLEARANCE,
        status: DQF_DOC_STATUS.CARRIER_UPLOAD_REQUESTED,
        action: ACTION_TYPE_COLLECT,
        reportData: this.dqfService.driverBirthLocationData?.reportData
      }
    };
    this.dqfService.updateDqfStatus(this.dqfService.dqfId, model).subscribe(
      () => {
        if (model.attachedDocument.reportData?.convictions?.length) {
          this.toastService.showSuccess(ADDED_RECORD);
        } else {
          this.toastService.showSuccess(REQUEST_SUCCESS);
        }
        this.loading = false;
        dialogRef.close();
        // route to new page
        this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_BACKGROUND_CHECK_PROCESSING}`], {
          queryParams: { documentType: DOCUMENT_TYPE }
        });
      },
      error => {
        this.loading = false;
        dialogRef.close();
        return of(false);
      }
    );
  }

  onCancel(): void {
    this.editRecordIndex = -1;
  }

  onUpdateConviction(data: any, index: number) {
    this.showAddedConvictions = !this.showAddedConvictions;
    this.showConvictionToAdd = true;
    this.criminalConvictionForm.setValue({
      convictionOffense: data.offense,
      dateOfSentence: data.sentenceDate,
      courtLocation: data.courtLocation,
      description: data.description
    });
    this.editRecordIndex = index;
  }

  onDeleteConviction(index: number) {
    this.dqfService.driverBirthLocationData?.reportData?.convictions.splice(index, 1);
  }

  navigateBack(): void {
    if (this.showAddedConvictions) {
      this.noConvictions = null;
      this.showConvictionToAdd = false;
      this.showAddedConvictions = !this.showAddedConvictions;
      this.criminalConvictionForm.reset();
    } else {
      this.location.back();
    }
  }

  cancelConviction() {
    if (this.dqfService.driverBirthLocationData?.reportData?.convictions?.length) {
      this.showAddedConvictions = !this.showAddedConvictions;
    } else {
      this.noConvictions = null;
      this.showConvictionToAdd = false;
      this.criminalConvictionForm.reset();
    }
  }

  private getPlaceAutocomplete(courtLocation: any): void {
    const autocomplete = new google.maps.places.Autocomplete(courtLocation.nativeElement, {
      types: ['(regions)']
      // remove  componentRestrictions for country if we have more than 5 countries
    });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      if (place) {
        this.zone.run(() => {
          this.criminalConvictionForm.patchValue({ courtLocation: place.formatted_address });
        });
      }
    });
  }

  private dialogCancelAction(dialogRef: MatDialogRef<any>, confirmAction: Action, cancelAction: Action): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    dialogRef.close();
  }

  private createAcceptDialogActions(): Action[] {
    const actions: Action[] = [];
    const NoAction = {
      name: 'Cancel',
      action: (data: any) => {},
      disabled: false
    } as Action;
    actions.push(NoAction);

    const OkayAction = {
      name: 'Proceed',
      action: (data: any) => {},
      disabled: false,
      color: 'primary'
    } as Action;
    actions.push(OkayAction);

    return actions;
  }
}
